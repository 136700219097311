import React, {useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import "./scss/global.scss";
import {
  loadReCaptcha
} from 'react-recaptcha-v3'

// Component
// =================================================
import { Footer } from "./components";

// Pages
// ==================================================
import { Homepage, Search, About, Team, Services, ServiceDetails, ContactUs, Clients, Projects, ProjectDetails, Notfound } from "./pages";
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  loadBannerData
} from './store/banner'

const App = () => {
  const dispatch = useDispatch()
  const {
     loading,
     loaded
   } = useSelector(state => state.banner)

  if(!loaded){
     dispatch(loadBannerData())
  }
    useEffect(() => {
      loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY, (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
      });
    }, []);

  
  return (
    <>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/search" component={Search} />
        <Route path="/about" component={About} />
        <Route path="/tentang" component={About} />
        <Route path="/team" component={Team} />
        <Route path="/services" component={Services} />
        <Route path="/layanan" component={Services} />
        <Route path="/service-details" component={ServiceDetails} />
        <Route path="/detail-layanan" component={ServiceDetails} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/kontak" component={ContactUs} />
        <Route path="/our-client" component={Clients} />
        <Route path="/klien-kami" component={Clients} />
        <Route path="/projects" component={Projects} />
        <Route path="/proyek" component={Projects} />
        <Route path="/project-details" component={ProjectDetails} />
        <Route component={Notfound} />
        {/* <Route name="storybook" path="/storybook" component={Storybook} /> */}
      </Switch>
      <Footer />
    </>
  );
};

export default App;
