import * as React from "react";

function SvgEmail(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.484 0H3.516A3.513 3.513 0 000 3.5v21C0 26.437 1.586 28 3.516 28h32.968C38.414 28 40 26.444 40 24.5v-21C40 1.579 38.43 0 36.484 0zm-.492 2.333c-.719.708-13.078 12.95-13.508 13.378A3.501 3.501 0 0120 16.738c-.938 0-1.82-.366-2.484-1.027C17.226 15.423 5 3.313 4.008 2.333h31.984zM2.344 24.026V3.974l10.125 10.034L2.344 24.026zm1.664 1.64L14.133 15.65l1.726 1.703A5.828 5.828 0 0020 19.063a5.84 5.84 0 004.14-1.703l1.727-1.711 10.125 10.018H4.008zm33.648-1.64L27.531 14.008 37.656 3.974v20.052z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgEmail;
