import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import style from './SearchResult.module.scss';

class SearchResult extends PureComponent {
  render() {
    const { page, breadcrumbs, link } = this.props;

    return (
      <Link className={style.result__link} to={link}>
        <div className={style.container}>
          <div className={style.pageName}>{page}</div>
          <div className={style.breadcrumbs}>
            {
              breadcrumbs.map((_el, i) => {
                if (i !== 0) {
                  return (
                    <>
                      <span className={style.separator} key={'breadcrumb-' + i}>&gt;</span>
                      <span>{_el}</span>
                    </>
                  )
                } else {
                  return <span>{_el}</span>
                }
              })
            }
          </div>
        </div>
      </Link>
    )
  }
}

export default SearchResult;