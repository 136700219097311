import React, { PureComponent } from "react";
import Select from "react-select";

import style from "./Input.module.scss";

class Dropdown extends PureComponent {
  render() {
    const { data, defaultValue, value, onChange, glossary } = this.props;

    return (
      <div className={style.select__container}>
        <div className={`${style[`select__container--inner`]}`}>
          <Select
            defaultValue={defaultValue}
            value={value}
            placeholder={glossary.select||'Select...'}
            onChange={onChange}
            options={data}
            className="searchable-select__container"
            classNamePrefix="searchable-select"
          />
        </div>
      </div>
    )
  }
}

export default Dropdown;