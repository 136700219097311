import * as React from "react";

function SvgUp(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16l2.82 2.82L14 7.66V32h4V7.66l11.16 11.18L32 16 16 0 0 16z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgUp;
