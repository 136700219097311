import React, { PureComponent } from "react";

import style from "./TitleBanner.module.scss";

class TitleBanner extends PureComponent {
  render() {
    const { title, pattern, backgroundSize } = this.props;

    return (
      <div className={style.container}>
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 0,
          backgroundImage: `url(${pattern})`,
          backgroundRepeat: "repeat",
          backgroundSize
        }} />
        <div className={style.container__inner}>
          <div className={style.title}>{title}</div>
        </div>
      </div>
    )
  }
}

export default TitleBanner;