import * as React from "react";

function SvgLeftWhite(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 29L2 15 16 1" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}

export default SvgLeftWhite;
