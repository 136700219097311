import React, { Component } from "react";
import { Link } from "react-router-dom";
import withSizes from "react-sizes";
import Cookies from "js-cookie";
import { Icon } from '../../components';

import style from "./Footer.module.scss";
import { useSelector} from 'react-redux';

function HookWrapper({
  render
}) {
  return render(loadCompanyProfile(), loadGlossary(), loadFooterImage());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)
  
  return glossary
}

function loadCompanyProfile() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    
    const companyinformation = banners.companyinformation
    //const domicile = banners.domicile
     
    return {
      address: banners.address != undefined ? banners.address : '',
      phone: companyinformation.phone !=undefined ? companyinformation.phone: '',
      copyright: banners.copyright != undefined ? banners.copyright : '',
      socialmedia: banners.socialmedia != undefined ? banners.socialmedia : [],
    }


  } else {
    let info = {
      address: 'Mega Boulevard Blok RV 2 No 3B, Tarumajaya Bekasi, Jawa Barat 17214',
      phone: '+62 (021) 94948869',
      copyright: 'Copyright 2021. ALL RIGHTS RESERVED',
      socialmedia: []
    }
    return info;
  }
}

function loadFooterImage() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return  banners.imagefooter != undefined ? banners.imagefooter : ''
  } else {
   return '';
  }
}

class Footer extends Component {

  _renderSocialMedia = (el, index) => {
     let socmed = '';
     if(el.socialmedia === 'facebook'){
       socmed = 'fb';
     }
     if(el.socialmedia === 'instagram'){
       socmed = 'ig';
     }
     if(el.socialmedia === 'twitter'){
       socmed = 'twt';
     }
     if(el.socialmedia === 'youtube'){
       socmed = 'yt';
     }
			return (
			     <a href={el.url} target="_blank" className={`${style[`footer__socmed--link`]}`} key={index}>
              <Icon type={socmed} className={`${style[`footer__socmed--icon`]}`} />
            </a>
			)
    
  }

  render() {
    const { isMobile } = this.props;
    const locale = Cookies.get("locale") || "EN"

    return (
      <HookWrapper render={ (settings,glossary, footerImage) =>
      <div className={style.footer}>
        <div className={style.footer__container}>
          {!isMobile ? (
            <div className={style.footer__desktop}>
              <div className={`${style[`footer__container--section`]}`}>
                <div className={`${style[`footer__container--logo`]}`}>
                  <img src={footerImage !=''? footerImage : '/assets/logo-vertical.svg'}  alt="footer-logo" className={`${style[`footer--logo`]}`} />
                </div>
                <div className={`${style[`footer__container--link`]}`}>
                  <Link to={locale === 'EN' ?'/about' : '/tentang'} className={style.footer__link}>
                    <span>{ glossary.about || 'About'}</span>
                  </Link>
                  <Link to={locale === 'EN' ?'/projects' : '/proyek'} className={style.footer__link}>
                    <span>{ glossary.projects || 'Projects'}</span>
                  </Link>
                  <Link to={locale === 'EN' ?'/services' : '/layanan'} className={style.footer__link}>
                    <span>{ glossary.services || 'Services'}</span>
                  </Link>
                  <Link to={locale === 'EN' ?'/our-client' : '/klien-kami'} className={style.footer__link}>
                    <span>{ glossary.clients || 'Our Clients'}</span>
                  </Link>
                  <Link to={locale === 'EN' ?'/contact' : '/kontak'} className={style.footer__link}>
                    <span>{ glossary.contact || 'Contact'}</span>
                  </Link>
                </div>
              </div>
              <div className={`${style[`footer__container--section`]}`}>
                <div className={`${style[`footer__container--address`]}`}>
                  <span>{settings.address}</span>
                  <br />
                  <span>{settings.phone}</span>
                </div>
                <div className={`${style[`footer__socmed`]}`}>
                  <span className={`${style[`footer__socmed--title`]}`}>{glossary.followUs|| 'Follow us:'}:</span>
                  <div className={`${style[`footer__socmed--container`]}`}>
                  {settings.socialmedia.map((_el, index) => (
                    <>{this._renderSocialMedia(_el, index)}</>
                  ))}
                  </div>
                </div>
              </div>
              <div className={style.footer__separator} />
              <footer>
                <small className={style.copyright}>{settings.copyright}</small>
              </footer>
            </div>
          ) : (
            <>
              <div className={`${style[`footer__container--logo`]}`}>
                <img src={footerImage !=''? footerImage : '/assets/logo-vertical.svg'} alt="footer-logo" className={`${style[`footer--logo`]}`} />
              </div>
              <div className={`${style[`footer__container--address`]} ${style[`footer__address--mbl`]}`}>
                <span>{settings.address}</span>
                <br />
                <span>{settings.phone}</span>
              </div>
              <div className={`${style[`footer__mbl--link`]}`}>
                <Link to={locale === 'EN' ?'/about' : '/tentang'} className={style.footer__link}>
                  <span>{ glossary.about || 'About'}</span>
                </Link>
                <Link to={locale === 'EN' ?'/projects' : '/proyek'} className={style.footer__link}>
                  <span>{ glossary.projects || 'Projects'}</span>
                </Link>
                <Link to={locale === 'EN' ?'/services' : '/layanan'} className={style.footer__link}>
                  <span>{ glossary.services || 'Services'}</span>
                </Link>
                <Link to={locale === 'EN' ?'/our-client' : '/klien-kami'} className={style.footer__link}>
                  <span>{ glossary.clients || 'Our Clients'}</span>
                </Link>
                <Link to={locale === 'EN' ?'/contact' : '/kontak'} className={style.footer__link}>
                  <span>{ glossary.contact || 'Contact'}</span>
                </Link>
              </div>
              <div className={`${style[`footer__socmed`]}`}>
                <span className={`${style[`footer__socmed--title`]}`}>{glossary.followUs|| 'Follow us:'}:</span>
                <div className={`${style[`footer__socmed--container`]}`}>
                  {settings.socialmedia.map((_el, index) => (
                    <>{this._renderSocialMedia(_el, index)}</>
                  ))}
                </div>
              </div>
              <div className={style.footer__separator} />
              <footer>
                <small className={style.copyright}>{settings.copyright}</small>
              </footer>
            </>
          )}
        </div>
      </div>
      }/>
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapStateToProps)(Footer);