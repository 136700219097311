import React, { PureComponent } from "react";
import {
  Link,
  useHistory
} from "react-router-dom";

import { Icon, Menu } from "../../components";

import style from "./Header.module.scss";

import Cookies from "js-cookie";
import { useSelector} from 'react-redux';

function HookWrapper({
  render
}) {
  return render(history(), loadGlossary(), loadSocialMedia(), loadHeaderImage());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function loadSocialMedia() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return  banners.socialmedia != undefined ? banners.socialmedia : []
  } else {
   return [];
  }
}

function loadHeaderImage() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return  banners.imageheader != undefined ? banners.imageheader : ''
  } else {
   return '';
  }
}

function history() {
  const history = useHistory();
  return history;
}

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.setTextInputRef = element => {
      this.textInput = element;
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.textInput) this.textInput.focus();
    };

    this.state = {
      search: false,
      menu: false,
      lang: 'EN',
      value:''
    }
    this._setLang = this._setLang.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this._toggleMenu = this._toggleMenu.bind(this);
  }

  _showSearch = () => {
    this.setState({
      search: true,
    });
  };

  _hideSearch = () => {
    this.setState({
      search: false,
      value: '',
    });
  };

   _setLang = (lg) => {

    Cookies.set("locale", lg);

    this.setState({
      lang: lg
    })
     setTimeout(() => {
         document.location.reload();
                      }, 1000);  
   
   }

  componentDidMount() {
    let locale = Cookies.get("locale") || "EN"
    this.setState({
      lang: locale
    })
  }

  _toggleMenu = () => {
    const { menu } = this.state;

    if (menu) {
      this.setState({
        menu: false
      })
    } else {
      this.setState({
        menu: true
      })
    }
  }

  _renderHeaderMobile = () => {
    const { menu, lang, search } = this.state;
    const { theme } = this.props;

    let en_active = '';
    let id_active = '';

    if(lang === 'EN' ){
      en_active = style[`language-active`];
      id_active = '' ; 
    }else{
       en_active = '';
       id_active = style[`language-active`];
    }

    return (
      <div className={`${style[`header__container--mobile`]}`}>
        {
          menu && (
            <div className={`${style[`header__container--language`]}`}>
              <div className={`${style.language__link} ${en_active}`} onClick={()=>this._setLang('EN')}>
                <span>EN</span>
              </div>
              <div className={style.language__separator} />
              <div className={`${style.language__link} ${id_active}`} onClick={()=>this._setLang('ID')}>
                <span>ID</span>
              </div>
            </div>
          )
        }
        <div
          className={`${style[`header__mobile--icon`]}`}
          onClick={() => this._toggleMenu()}
        >
          {
            menu ? (
              <Icon
                type={theme !== "white" ? "close" : "closewhite"}
                className={style.header__icon}
              />
            ) : (
              <Icon
                type={theme !== "white" ? "hamburger" : "hamburgerwhite"}
                className={style.header__icon}
              />
            )
          }
        </div>
      </div>
    );
  }
  handleChange(event) {
    this.setState({value: event.target.value});
  }
  handleSubmit(event, history) {
    event.preventDefault();
    const value = this.state.value;
    if(value !==''){
      history.push("/search?keyword="+value)
    }
    
    this.setState({value: ''});
  }

  _renderSearch = (history, glossary, socialmedia) => {
    const { search, value } = this.state;
    setTimeout(() => {
      this.focusTextInput();
    }, 500);
   
    return (
      <div className={`${style[`header__container--search`]} ${search ? style[`header__container--search-open`] : null}`}>
        <form onSubmit={(event)=>this.handleSubmit(event,history)}>
          <div className={style.header__search}>
            <input ref={this.setTextInputRef} value={value} placeholder = { glossary.search || 'Search'} className = { `${style[`header__search--input`]}` } onChange = { this.handleChange } />
            {/* Showcase purpose only, use button for the real practice */}
            <Link to={'/search?keyword='+value} className={`${style[`header__search--button`]}`}>
              <Icon type="searchwhite" className={style.header__icon} />
            </Link>
            {/* <button className={`${style[`header__search--button`]}`}>
              <Icon type="searchwhite" className={style.header__icon} />
            </button> */}
          </div>
        </form>
        <div className={`${style[`header__container--close`]}`} onClick={() => this._hideSearch()}>
          <Icon type="closewhite" className={`${style[`header__icon--close`]}`} />
        </div>
      </div>
    )
  }

  _renderMenu = (glossary) => {
    const { search, lang } = this.state;
    const { theme } = this.props;

    let en_active = '';
    let id_active = '';

    if(lang === 'EN' ){
      en_active = style[`language-active`];
      id_active = '' ; 
    }else{
       en_active = '';
       id_active = style[`language-active`];
    }

    return (
      <div className={`${style[`header__container--link`]} ${search ? style[`header__container--link-hide`] : null}`}>
        <div className={`${style[`header__container--inner`]}`}>
          <Link to={lang === 'EN' ?'/about' : '/tentang'} className={`${style.header__link} ${theme !== "white" ? style[`header__link-black`] : null}`}>
            <span> { glossary.about || 'About'}</span>
          </Link>
          <Link  to={lang === 'EN' ?'/projects' : '/proyek'} className={`${style.header__link} ${theme !== "white" ? style[`header__link-black`] : null}`}>
            <span>{ glossary.projects || 'Projects'}</span>
          </Link>
          <Link to={lang === 'EN' ?'/services' : '/layanan'} className={`${style.header__link} ${theme !== "white" ? style[`header__link-black`] : null}`}>
            <span>{ glossary.services || 'Services'}</span>
          </Link>
          <Link to={lang === 'EN' ?'/our-client' : '/klien-kami'} className={`${style.header__link} ${theme !== "white" ? style[`header__link-black`] : null}`}>
            <span>{ glossary.clients || 'Our Clients'}</span>
          </Link>
          <Link to={lang === 'EN' ?'/contact' : '/kontak'} className={`${style.header__link} ${theme !== "white" ? style[`header__link-black`] : null}`}>
            <span>{ glossary.contact || 'Contact'}</span>
          </Link>
          <div className={`${style[`header__container--language`]}`} >
            <div className={`${style.language__link} ${en_active}`} onClick={()=>this._setLang('EN')}>
              <span>EN</span>
            </div>
            <div className={style.language__separator} />
            <div className={`${style.language__link} ${id_active}`}  onClick={()=>this._setLang('ID')}>
              <span>ID</span>
            </div>
          </div>
        </div>
        <div className={`${style[`header__container--icon`]}`} onClick={() => this._showSearch()}>
          <Icon type={theme !== "white" ? "search" : "searchwhite"} className={style.header__icon} />
        </div>
      </div>
    )
  }

  render() {
    const { menu,search } = this.state;
    const { theme, type } = this.props;
   
    return (
      <HookWrapper render={ (history, glossary, socialmedia, headerImage) =>
      <>
        <div className={`${style.header} ${theme !== "white" ? style[`header-transparent`] : null}`}>
          <div className={style.header__container}>
            <Link to="/" className={`${style[`header__container--logo`]}`}>
              <img src={headerImage !=''? headerImage : '/assets/main-logo.svg'}  alt="main-logo" className={`${style[`header--logo`]}`} />
            </Link>
            {this._renderMenu(glossary)}
            {this._renderSearch(history, glossary)}
            {this._renderHeaderMobile()}
          </div>
        </div>
        <Menu visible={menu} glossary={glossary} history={history}  socialmedia={socialmedia} that={this}/>
      </>
      }/>
    );
  }
}

export default Header;

Header.defaultProps = {
  theme: 'white'
}