import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Icon } from "../../components";

import style from './Menu.module.scss';

class Menu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: false,
      value:''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      this.setState({value: ''});
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    this.setState({value: ''});
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }
  handleSubmit(event, history, that) {
    event.preventDefault();
    const value = this.state.value;
    history.push("/search?keyword="+value)
    this.setState({value: ''});
    that._toggleMenu()
  }

  _renderSocialMedia = (el, index) => {
    let socmed = '';
    if(el.socialmedia === 'facebook'){
      socmed = 'fbwhite';
    }
    if(el.socialmedia === 'instagram'){
      socmed = 'igwhite';
    }
    if(el.socialmedia === 'twitter'){
      socmed = 'twtwhite';
    }
    if(el.socialmedia === 'youtube'){
      socmed = 'ytwhite';
    }
     return (
           <a href={el.url} className={`${style[`menu__socmed--link`]}`} key={'menu-' + index}>
              <Icon type={socmed} className={`${style[`menu__socmed--icon`]}`} />
            </a>
     )
   
 }

  render() {
    const { visible, glossary, history, socialmedia, that } = this.props;
    const {value} = this.state
    const locale = Cookies.get("locale") || "EN"

    return (
      <div className={`${style.container} ${visible ? style[`menu-show`] : null}`}>
        <div className={style.container__inner}>
          <div className={style.menu__container}>
            <Link to={locale === 'EN' ?'/about' : '/tentang'} className={style.menu__link}>
              <span>{ glossary.about || 'About'}</span>
            </Link>
            <Link  to={locale === 'EN' ?'/projects' : '/proyek'} className={style.menu__link}>
              <span>{ glossary.projects || 'Projects'}</span>
            </Link>
            <Link to={locale === 'EN' ?'/services' : '/layanan'} className={style.menu__link}>
              <span>{ glossary.services || 'Services'}</span>
            </Link>
            <Link to={locale === 'EN' ?'/our-client' : '/klien-kami'} className={style.menu__link}>
              <span>{ glossary.clients || 'Our Clients'}</span>
            </Link>
            <Link to={locale === 'EN' ?'/contact' : '/kontak'} className={style.menu__link}>
              <span>{ glossary.contact || 'Contact'}</span>
            </Link>
          </div>
          <div className={`${style[`menu__search`]}`}>
          <form onSubmit={(event)=>this.handleSubmit(event,history, that)}>
            <input value={value} placeholder={ glossary.search || 'Search'} className={`${style[`menu__search--input`]}`} onChange = { this.handleChange }/>
            <button type="submit" className={`${style[`menu__search--button`]}`}>
              <Icon type="searchwhite" className={style.menu__icon} />
            </button>
          </form>
          </div>
          <div className={style.socmed__container}>
            {socialmedia.map((_el, index) => (
              this._renderSocialMedia(_el, index)
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Menu;