import React, { Component } from "react";

import style from "./Home.module.scss";
import { useSelector} from 'react-redux'
import MetaTags from 'react-meta-tags';

// Components
// ===========================================
import {
  Header,
  MainBanner,
  ProjectsBanner,
  SectionCareer,
  SectionDesign,
  SectionServices
} from "../../components";

// Dummy Data
// ===========================================
import { banner, design, projects, services, career } from '../../api/data';

function HookWrapper({
  render
}) {
   return render(loadSeo());
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)
  
  return banners
}
function loadSeo() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return banners.seo_home
  }else{
    return{
      title:"Aura Design",
      meta_keyword:"Aura Design",
      meta_description:"Aura Design",
    }
  }
 
}


class Homepage extends Component {
  render() {
    return (
       <HookWrapper render={ (seo) =>
          <div className={style.container}>
            <MetaTags>
              <title>{seo.title}</title>
              <meta name="description" content={seo.meta_description} />
              <meta name="keyword" content={seo.meta_keyword} />
              <meta property="og:title" content={seo.title} />
            </MetaTags>
            <Header />
            <MainBanner data={banner} banners={loadBanner()} />
            <SectionDesign title={design.title} content={design.content} data={loadBanner()} />
            <ProjectsBanner title={projects.title} caption={projects.caption} data={projects}  projects={loadBanner()} />
            <SectionServices data={services} services={loadBanner()}/>
            <SectionCareer title={career.title} content={career.content}  data={loadBanner()}/>
          </div>
       }/>
    );
  }
   
}


export default Homepage;
