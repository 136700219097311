import * as React from "react";

function SvgSearch(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17} cy={16} r={13} stroke="#000" strokeWidth={2} />
      <path d="M25 26l12 12" stroke="#000" strokeWidth={2} />
    </svg>
  );
}

export default SvgSearch;
