import React, { Component } from "react";

import { Button } from '../../components';

import style from './Section.module.scss';

class SectionDesign extends Component {
  render() {
    const { title, content, data } = this.props;

    let caption, description, image, buttonText, link
    
      caption = title
      description = content
      image = '/assets/design.png'
      buttonText = 'Learn More'
      link = '#'

    if (data) {
       const about = data.about
       caption = about.title
       description = about.description
       image = about.image
       buttonText = about.linkButton
       link = about.link
    }

    return (
      <section className={style.section}>
        <div className={`${style.section__inner} ${style[`section--design`]}`}>
          <div className={`${style.section__left} ${style[`design-left`]}`}>
            <h3 className={style.design__title}>{caption}</h3>
            <div className={style.design__caption}>{description}</div>
            <div className={style.button__panel}>
              <Button  buttonName={buttonText} url={link} type="outer-link"/>
            </div>
          </div>
          <div className={`${style.section__right} ${style[`design-right`]}`}>
            <div className={`${style[`section__container--image`]}`}>
              <img src={image} className={style.design__img} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionDesign;