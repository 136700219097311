import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { Header, SectionCareer, SectionIntro, ServicePanel } from "../../components";
import Cookies from "js-cookie";

import style from "./Services.module.scss";
import { career, service } from "../../api/data";
import pattern from "../../assets/patterns/pattern03.svg";
import { useSelector} from 'react-redux'

function HookWrapper({
  render
}) {
  return render(loadHeader(),loadServices(), loadGlossary(), loadSeo());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function loadHeader() {
	const {
		banners
	} = useSelector(state => state.banner)
	if (banners !== null){
      const services_banner = banners.services_banner
      const svg = banners.svg
      const svg_services = svg.svg_services
      const image_services = services_banner.image
      let svg_file = null
      if(svg_services.file !== undefined){
         svg_file = svg_services.file.file_url || null
      }else{
         svg_file =  null
      }
      
      let image_file = null
      if(image_services !== undefined){
         image_file = image_services.file_url || null
      }else{
         image_file =  null
      }
      
			let service_data = {
				title: services_banner.title,
				caption: services_banner.description,
				image: image_file || null,
				svg: svg_file || null
			}
		return service_data;
	}else{
		let info = {title: null, caption: null, image:null, svg: null}
		return info;
  }
}

function loadServices() {
	const {
		banners
	} = useSelector(state => state.banner)
	if (banners !== null){
			const services = banners.services
			
		return services;
	}else{
		let info =[]
		return info;
  }
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)

  return banners
}

function loadSeo() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return banners.seo_services
  }else{
    return{
      title:"Aura Design",
      meta_keyword:"Aura Design",
      meta_description:"Aura Design",
    }
  }
 
}

class Services extends Component {

  componentDidMount() {
    const {pathname} = this.props.location
    let locale = Cookies.get("locale") || "EN"
    
    if(locale == 'EN'&& pathname.includes('layanan')){
     setTimeout(() => {
       this.props.history.push("/services");
                    }, 500);  
    }
 
    if(locale == 'ID'&& pathname.includes('services')){
     setTimeout(() => {
       this.props.history.push("/layanan");
                    }, 500);  
    }
 
   }

  render() {
    const { data } = service;
    //const { services } = data;

    return (
      <HookWrapper render={ (props, services, glossary, seo) =>
      <>
        <MetaTags>
          <title>{seo.title}</title>
          <meta name="description" content={seo.meta_description} />
          <meta name="keyword" content={seo.meta_keyword} />
          <meta property="og:title" content={seo.title} />
        </MetaTags>
        <Header />
        <SectionIntro title={props.title||data.title} caption={props.caption||data.caption} pattern={props.svg || pattern} />
        <div className={`${style[`service__container--banner`]}`}>
          <img src={props.image||"/assets/service-banner.png"} className={style.service__banner} />
        </div>
        <section className={style.services__container}>
          <div className={style.services__types}>
           {
            	services.length > 0 && services.map(_el => (
               <ServicePanel title={_el.title} caption={_el.subtitle} pattern={_el.file.file_url} data={services} id={_el.id} current_data={_el} glossary={glossary} />
             
            ))}
           
          </div>
        </section>
        <SectionCareer  title={career.title} content={career.content}  data={loadBanner()}/>
      </>
      }/>
    )
  }
}

export default Services;