import React, { PureComponent } from "react";
import { FacebookShareButton } from "react-share";

import { Icon } from "../../components";

import style from "./Share.module.scss";

class ShareFacebook extends PureComponent {
  render() {
    const { quote, hashtag, url, customStyle, customIconStyle } = this.props;

    return (
      <div className={`${style.share} ${customStyle}`}>
        <FacebookShareButton quote={quote} hashtag={hashtag} url={url}>
          <Icon type="fb" className={`${style.share__icon} ${customIconStyle}`} />
        </FacebookShareButton>
      </div>
    )
  }
}

export default ShareFacebook;