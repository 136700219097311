import React, { PureComponent } from "react";
import Slider from "react-slick";
import withSizes from "react-sizes";

import { Icon, Modal } from "../../components";

import style from "./TeamList.module.scss";

class TeamList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currSlide: 0
    }
  }

  _nextSlide = () => this.slider.slickNext();

  _prevSlide = () => this.slider.slickPrev();


  _renderMemberCard = (data) => {
    const { onClick } = this.props;

    return (
      <div className={style.member__card} onClick={() => onClick(data)}>
        <img src={data.photo} className={style.member__photo} />
        <div className={style.photo__overlay} />
        <div className={style.member__info}>
          <div className={style.member__name}>{data.name}</div>
          <div className={style.member__position}>{data.position}</div>
        </div>
      </div>
    )
  }

  _renderMemberList = members => {
    return (
      <>
        {
          members.map(_el =>
            <>
              {this._renderMemberCard(_el)}
            </>
          )
        }
      </>
    )
  }

  _renderMemberSlider = (members) => {
    const { onClick } = this.props;
    const settings = {
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      afterChange: next => this.setState({ currSlide: next })
    };

    return (
      <Slider ref={c => (this.slider = c)} {...settings}>
        {
          members.map(_el =>
            <div className={`${style.member__card} ${style.member__slider}`} onClick={() => onClick(_el)}>
              <img src={_el.photo} className={style.member__photo} />
              <div className={style.photo__overlay} />
              <div className={style.member__info}>
                <div className={style.member__name}>{_el.name}</div>
                <div className={style.member__position}>{_el.position}</div>
              </div>
            </div>
          )
        }
      </Slider>
    )
  }

  _renderLongList = (member) => {
    const { isSlider } = this.props;

    return (
      <>
        {isSlider ?
          this._renderMemberSlider(member) :
          <div className={`${style['team__list--scroll']}`}>{this._renderMemberList(member)}</div>
        }
      </>
    )
  }

  render() {
    const { currSlide } = this.state;
    const { data, isSlider } = this.props;
    const { division, member } = data;
    const len = member.length;

    return (
      <>
        <div className={style.team__container}>
          <div className={style.team__upper}>
            <div className={style.team__name}>{division}</div>
            {(len > 5 && isSlider) && (
              <div className={style.slider__arrow}>
                <div className={`${style.arrow} ${currSlide === 0 && style[`arrow-disabled`]}`} onClick={this._prevSlide}>
                  <Icon type="leftwhite" className={style.arrow__icon} />
                </div>
                <div className={`${style.arrow} ${len - currSlide <= 5 && style[`arrow-disabled`]}`} onClick={this._nextSlide}>
                  <Icon type="rightwhite" className={style.arrow__icon} />
                </div>
              </div>
            )}
          </div>
          {
            len > 5 ?
              this._renderLongList(member) :
              <div className={`${style.team__list} ${!isSlider && style['team__list--scroll']}`}>{this._renderMemberList(member)}</div>
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isSlider: width > 1150
})

export default withSizes(mapStateToProps)(TeamList);