import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import style from "./Button.module.scss";

const getClass = (full) => {
  const list = [style.button];
  list[list.length] = full ? [`${style[`button--full`]}`] : null;

  return list.join(" ");
};

class Button extends PureComponent {
  renderButton = () => {
    const { type, buttonName, full, link, url, onClick } = this.props;
    const button = buttonName.toUpperCase();

    if (type === "redirect") {
      return (
        <Link to={link} className={getClass(full)}>
          {button}
        </Link>
      );
    }
    if (type === "outer-link") {
      return (
        <a href={url} className={getClass(full)}>
          {button}
        </a>
      );
    }
    if (type === "submit") {
      return <button type={type} className={getClass(full)} onClick={onClick}>{button}</button>;
    }
    return <div className={getClass(full)} onClick={onClick}>{button}</div>;
  };

  render() {
    return <>{this.renderButton()}</>;
  }
}

export default Button;

Button.defaultProps = {
  type: "function",
  buttonName: "",
};
