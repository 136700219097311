import React, { PureComponent } from "react";

import { Icon, Button } from "..";
import style from "./Panel.module.scss";
import Cookies from "js-cookie";

class ServicePanel extends PureComponent {


  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }
  render() {
    const { title, caption, pattern, id, current_data, glossary } = this.props;
    let locale = Cookies.get("locale") || "EN"
    let action = "service-details"
    if(locale == "ID"){
      action = "detail-layanan"
    }
    // console.log("CURRENT DATA", current_data)

    return (
      <div className={style.container}>
        <div className={style.service__info}>
          <div className={style.service__header}>
            <Icon type="plus" className={style.service__icon} />
            <h4 className={style.service__name}>{title}</h4>
          </div>
          <p className={style.service__caption}>{caption}</p>
          <div className={style.button__panel}>
            <Button buttonName={glossary.learnMore || 'Learn More'} type="redirect" link={"/"+ action + "/" + this.slugify(title)} />
          </div>
        </div>
        <div className={style.service__pattern}>
          <div style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${pattern})`,
            backgroundSize: title === 'Architecture' ? 50 : 80
          }} />
        </div>
      </div>
    )
  }
}

export default ServicePanel;