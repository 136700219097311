import React, { PureComponent } from "react";
import Slider from "react-slick";

import { Icon } from '../../components';

import style from "./PageBanner.module.scss";

class PageBanner extends PureComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      currSlide: 0
    }
  }

  prevSlide = () => this.slider.slickPrev();

  nextSlide = () => this.slider.slickNext();

  render() {
    const { currSlide } = this.state;
    let { banner, data , sliders } = this.props;

    if(data!=null){
			let slider = data.about_slider
			banner = slider
    }
    
    if (sliders != null) {
      let slides = sliders.map(el=>{
        return {
          image: el.image.file_url
        }
      })

      banner = slides
    }

    let len = banner.length;
    const settings = {
      adaptiveHeight: false,
      arrows: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (next) => this.setState({ currSlide: next })
    }

    return (
      <div className={style.container}>
        <div className={style.banner__container} id="page-slider">
          <Slider ref={c => (this.slider = c)} {...settings}>
            {banner.map((_el, i) => {
              return (
                <div className={style.banner} key={`banner-${i}`}>
                  <img src={_el.image} className={style.banner__image} />
                </div>
              )
            })}
          </Slider>
        </div>
        <div className={style.arrows__container}>
          <div className={`${style[`arrows__container--inner`]}`}>
            <div className={`${style.arrow} ${currSlide === 0 && style[`arrow-disabled`]}`} onClick={() => this.prevSlide()}>
              <Icon type={currSlide === 0 ? "left" : "leftwhite"} className={style.arrow__icon} />
            </div>
            <div className={`${style.arrow} ${currSlide + 1 === len && style[`arrow-disabled`]}`} onClick={() => this.nextSlide()}>
              <Icon type={currSlide + 1 === len ? "right" : "rightwhite"} className={style.arrow__icon} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PageBanner;