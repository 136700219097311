import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { Icon, Button } from '../../components';

import style from "./ProjectsBanner.module.scss";

class ProjectsBanner extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      slider: 0
    }
  }

  prevSlide = () => this.slider.slickPrev();

  nextSlide = () => this.slider.slickNext();

  render() {
    const { slider } = this.state;
    const {
      title,
      caption,
      data,
      projects
    } = this.props;
    let { banner } = data;
    let len = banner.length;
    // console.log("BANNER", banner)
    const settings = {
      adaptiveHeight: true,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (next) => this.setState({ slider: next })
    }
    let header = title
    let description = caption
    let linkButton = 'Latest Project'
    let link = '#'

    if (projects) {
      let data = projects.projects
      header = data.title
      description = data.description
      linkButton = data.linkButton
      link = data.link
      if (projects.projects_slider != undefined) {
        banner = projects.projects_slider
      }
    }

    return (
      <div className={style.container}>
        <div className={style.container__banner}>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {banner.map((_el, i) => {
              return (
                <div className={style.banner} key={`banner-${i}`}>
                  <img src={_el.image} className={style.banner__image} />
                </div>
              )
            })}
          </Slider>
        </div>
        <div className={style.container__inner}>
          <div className={style.arrow__container}>
            <div className={`${style.arrow} ${style[`arrow--left`]}`} onClick={() => this.prevSlide()}>
              <Icon type="leftwhite" className={style.arrow__icon} />
            </div>
            <div className={style.arrow} onClick={() => this.nextSlide()}>
              <Icon type="rightwhite" className={style.arrow__icon} />
            </div>
          </div>
          <div className={style.section__content}>
            <h3 className={style.section__title}>{header}</h3>
            <p className={style.section__text}>{description}</p>
            <div className={style.button__panel}>
              <Button buttonName={linkButton}  url={link} type="outer-link"/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectsBanner;

