import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

import {
  Header,
  ButtonScroll,
  DetailsBanner,
  ContentPicture,
  Navbar,
  SectionCareer,
  ContentSidePicture,
  ContentPictureText,
  ContentMain,
  MapView
} from "../../components";

import style from "./ProjectDetails.module.scss";
import { career,locations } from "../../api/data";
import { useSelector} from 'react-redux';



function HookWrapper({
  render
}) {
  return render(loadDetail(), loadGlossary());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function loadDetail() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    
    const project_lists = banners.project_lists
    const thePath = document.location.href
    const lastItem = thePath.substring(thePath.lastIndexOf('/') + 1)
    
    let current = project_lists.filter(el=>{
      return (el.slug === lastItem);
    })

    let i = 0;
    project_lists.map((el, index)=>{
      if (el.slug === lastItem){
         i = index
      }
    })

    let prevItem = null
    let nextItem = null
    let prevLink = ""
    let nextLink = ""
    if(i >  0){
      prevItem  = project_lists[i-1];
      prevLink = "" + prevItem.slug
    }
    if(i !==  (project_lists.length-1)){
      nextItem  = project_lists[i+1];
      nextLink = "" + nextItem.slug
    }


    // console.log("lastItem", lastItem)
    // console.log("project_lists", project_lists)
    // console.log("CURRENT", current)
    let current_data = {
      title:null,
      caption:null,
      description:null,
      sliders: [],
      image:{
        file_url:null
      }
    }

    if(current.length >0){
      let curr = current[0]
      current_data = {
        title: curr.title,
        intro: curr.intro.caption,
        content: curr.intro.description,
        description: curr.description,
        website: curr.website,
        logo: curr.image.file_url,
        banner: curr.image3.file_url,
        article: curr.article,
        article2: curr.article2,
        locations: curr.locations,
        prevItem: prevItem,
        nextItem: nextItem,
        prevLink: prevLink,
        nextLink: nextLink
      }
    }

    return current_data;
  } else {
    let info = {
      title: null,
      intro: null,
      content: null,
      description: null,
      website: null,
      logo: null,
      banner: null,
      article: {
        title:'',
        image1:{
          file_url:''
        },
        image2:{
          file_url:''
        },
        image3:{
          file_url:''
        }
      },
      article2:{
        title:'',
        image1:{
          file_url:''
        },
        image2:{
          file_url:''
        },
        image3:{
          file_url:''
        },
        image4:{
          file_url:''
        }
      },
      locations:[],
      prevItem: null,
      nextItem: null,
      prevLink: "",
      nextLink: ""
    }
    return info;
  }
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)

  return banners
}

class ProjectDetails extends Component {

   countLocation(data) {
    if(data === undefined){
      return 0
    }
    if(data.length > 0){
      let loc = [];
      data.map(item => {
        if (item.locations !== undefined) {
          item.locations.map(it => {
            loc.push(it)
          })
        }

      })
      return loc.length
    }else{
      return 0
    }  
   }

  render() {
    const { data } = locations;

    return (
      <HookWrapper render={ (detail, glossary) =>
      <>
        <MetaTags>
          <title>{detail.title}</title>
          <meta name="description" content={detail.title} />
          <meta name="keyword" content={detail.title} />
          <meta property="og:title" content={detail.title} />
        </MetaTags>
        <Header />
        <DetailsBanner
          image={detail.banner} />
        <div className={style.details__content}>
          <div className={style.details__inner}>
            <ContentMain logo={detail.logo}
              intro={detail.intro}
              title={detail.title}
              content={detail.content}
              website={detail.website}
              data={detail.locations}
              countloc={this.countLocation(detail.locations)}
              glossary={glossary}
            />
            <div className={style.content__mb80}>
              <ContentPictureText
                image={detail.article?.image1?.file_url || ''}
                title={detail.article?.title || ''}>
               {detail.article?.description || ''}
            </ContentPictureText>
            </div>
            <ContentPicture image={detail.article?.image2?.file_url || ''} />
            <ContentPicture image={detail.article?.image3?.file_url || ''} />
            <div className={style.content__m80}>
              <ContentPictureText
                image={detail.article2?.image1?.file_url || ''}
                title={detail.article2?.title || ''}>
                {detail.article?.description || ''}
            </ContentPictureText>
            </div>
            <ContentPicture image={detail.article2?.image2?.file_url || ''} />
            <div className={style.content__mt80}>
              <ContentSidePicture
                imageLeft={detail.article2?.image3?.file_url || ''}
                imageRight={detail.article2?.image4?.file_url || ''} />
            </div>
            {
              this.countLocation(detail.locations) > 0 && (<MapView data={detail.locations}  glossary={glossary}/>)
            } 
          </div>
        </div>
        <Navbar
          type="image"
          prevLogo="/assets/hnm-logo.png"
          nextLogo="/assets/everbest-logo.png"
          prevItem={detail.prevItem}
          nextItem={detail.nextItem}
          nextLink={detail.nextLink}
          prevLink={detail.prevLink}
          glossary={glossary}
        />
        <SectionCareer title={career.title} content={career.content} data={loadBanner()}/>
        <ButtonScroll />
      </>
       }/>
    )
  }
}

export default ProjectDetails;