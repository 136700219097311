import React, { PureComponent } from "react";

import { Icon } from "../../components";

import style from "./Button.module.scss";

class ButtonScroll extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._setVisibility);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._setVisibility);
  }

  _setVisibility = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 300) {
      this.setState({
        visible: true
      })
    } else {
      this.setState({
        visible: false
      })
    }
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  render() {
    const { visible } = this.state;

    return (
      <div className={`${style.button__scroll} ${visible && style[`button-visible`]}`} onClick={this.scrollToTop}>
        <Icon type="up" className={`${style[`button__scroll--icon`]}`} />
      </div>
    )
  }
}

export default ButtonScroll;