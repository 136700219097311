import * as React from "react";

function SvgCloseWhite(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 38L38 2M2 2l36 36" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}

export default SvgCloseWhite;
