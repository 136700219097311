import React from "react";

import style from './Input.module.scss';

const getClass = (err, type, full, value) => {
  const list = [`${style.input}`];
  list[list.length] = value !== '' && !err ? `${style[`input-value`]}` : null;
  list[list.length] = full ? `${style[`input-full`]}` : null;
  list[list.length] = type === "textarea" ? `${style.textarea}` : null;
  list[list.length] = err ? `${style[`input--error`]}` : null;

  return list.join(" ");
}

const errorMessage = (err, message) => {
  if (err)
    return <div className={style.input__message}>{message}</div>
}

const Input = props => {
  const { id, name, error, message, type, disabled, placeholder, value, onChange, full } = props;

  const InputTag = type === "textarea" ? "textarea" : "input";
  const actualId = id !== "" ? id : name;

  return (
    <div className={style.input__wrapper}>
      <div className={style.input__container}>
        <InputTag
          id={actualId}
          className={getClass(error, type, full, value)}
          type={type}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
      {errorMessage(error, message)}
    </div>
  )
}

export default Input;