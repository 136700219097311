import React, { PureComponent } from "react";
import Slider from "react-slick";

import style from "./MainBanner.module.scss";

class MainBanner extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      slider: 0,
      init: true,
      currTitle: '',
      buttonText: '',
      link: ''
    }
  }

  _navBanner = (index, title, buttonText,link) => {
    this.setState({
      currTitle: title,
      buttonText: buttonText,
      link: link
    }, () => this.banner.slickGoTo(index))
  }

  render() {
    const { slider, currTitle, buttonText, link  } = this.state;
    const { data } = this.props;
    let banner
    const { banners } =this.props;
    if(banners?.banner?.length > 0){
      let sliders = banners.banner
      banner = sliders.map(item=>{
        return {
          title : item.title,
          image: item.image,
          buttonText: item.buttonText,
          link: item.link
        }
      })
      if(banner && banner.length > 0 && this.state.init) {
        this.setState({ currTitle: banner[0]?.title, init:false, buttonText: banner[0]?.buttonText, link:banner[0]?.link })
      }
    }else{
      banner = [];
    }

    const settings = {
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (curr, next) => this.setState({ slider: next }),
      afterChange: (next) => this.setState({ currTitle: banner[next]?.title }),
      onInit: () => this.setState({ currTitle: banner[0]?.title || '', buttonText: banner[0]?.buttonText || '', link: banner[0]?.link || ''})
    };

    return (
      (banner && banner.length) > 0 ? (
      <div className={style.container}>
        <Slider ref={banner => (this.banner = banner)} {...settings}>
          {banner && banner.map((_el, i) => {
            return (
              <div className={style.banner} key={`banner-${i}`}>
                <img src={_el.image} className={style.banner__img} />
                <div className={style.banner__overlay} />
              </div>
            )
          })}
        </Slider>
        <div className={style.banner__inner}>
          <div className={style.banner__props}>
            <div className={style.banner__count}>
              <div>0{slider + 1}</div>
              <div className={`${style[`banner__count--separator`]}`} />
              <div>0{banner.length}</div>
            </div>
            <div className={`${style[`banner__props--right`]}`}>
              <div className={`${style[`banner__props--title`]}`}>{currTitle}</div>
              <a href={link} className={style.banner__link}>{buttonText}</a>
            </div>
          </div>
          {this._renderPaging()}
        </div>
      </div>) : (<div className={style.container} style={{height: '100vh'}}></div>)
    );
  }

  _renderPaging = () => {
    const { slider } = this.state;
    const { data } = this.props;
    const { banners } =this.props;
    let banner

    if (banners) {
      banner = banners.banner
    } else {
      banner = data.banner
    }

    return (
      <div className={style.banner__paging}>
        {(banner && banner.length > 0) && banner.map((_el, i) => (
          <div className={`${style[`banner__container--paging`]} ${slider === i && style[`banner__container-active`]}`} onClick={() => this._navBanner(i, _el.title, _el.buttonText, _el.link)} key={`paging-${i}`}>
            <div className={style.banner__number}>0{i + 1}</div>
            <h1 className={style.banner__title}>{_el.title}</h1>
          </div>
        ))}
      </div>
    )
  }
}

export default MainBanner;
