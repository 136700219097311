import React, { Component } from "react";

import { ShareFacebook, ShareTwitter, ShareEmail, LocationModal } from "../../components";

import style from "./Content.module.scss";

class ContentMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: false
    }
  }

  _openLocation = () => {
    this.setState({
      location: true
    })
  }

  _closeLocation = () => {
    this.setState({
      location: false
    })
  }

  render() {
    const { location } = this.state;
    const { data, logo, website,title, intro, content ,countloc, glossary } = this.props;

    return (
      <>
        <div className={style.content__container}>
          <div className={`${style[`content__container--inner`]}`}>
            <img src={logo} className={style.content__logo} />
            <div className={`${style[`content__line--main`]}`} />
            <div className={`${style.content__title} ${style[`content__title--main`]}`}>{intro}</div>
            <div className={style.main__container}>
              <div className={style.main__content}>
                <p className={style.project__intro}>{content}</p>
              </div>
              <div className={style.main__content}>
                <div className={style.content__separator}>
                  <div className={style.content__label}>{glossary.website||'Website'}:</div>
                  <div className={style.content__text}>{website}</div>
                </div>
                <div className={style.content__separator}>
                <>{
                  countloc > 0 && ( <><div className={style.content__label}>{glossary.location||'Location'}:</div>
                  <>{
                      countloc < 6 &&(
                        <ul className={style.content__list}>
                          {
                            data.map(el=>{
                              return(
                                <>{el.locations.map((_el, index) => {
                                    return ( <li key={'location-' + index} className={style.content__text}>{_el.label} ({_el.year}) {glossary.area || 'approx area'} {_el.area} {glossary.sqm || 'sqm'}</li>)
                                  })}</>
                              )      
                              
                            })
                          }
                        </ul>

                      )
                  }</>
                  <>{
                      countloc > 5 &&(
                        <div className={style.content__text}>{countloc} {glossary.locations||'Locations'}</div>

                      )
                  }</> </>)
                }</>
                
                  {/* <ul className={style.content__list}>
                  <li className={style.content__text}>Grand Indonesia (2013) approx area 3400 sqm</li>
                  <li className={style.content__text}>Kota Kasablanka (2014) approx area 1800 sqm</li>
                  <li className={style.content__text}>Paris Van Java (2014) approx area 1600 sqm</li>
                </ul> */}
                {
                  countloc > 0 && ( <div className={style.content__link} onClick={this._openLocation}>{glossary.viewAllLocation||'View All Location'}</div>)
                }
                
                  <div className={style.content__socmed}>
                    <span className={style.share__label}>{glossary.share||'Share'}</span>
                    <ShareFacebook url={ document.location.href}  quote={intro} />
                    <ShareTwitter url={ document.location.href} title={title} />
                    <ShareEmail url={ document.location.href} subject={title} body={intro} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LocationModal visible={location} title={glossary.allLocation||'All Location'} data={data} onClose={this._closeLocation} glossary={glossary} />
      </>
    )
  }
}

export default ContentMain;