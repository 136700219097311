import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import style from "./Category.module.scss";

class Category extends PureComponent {
  render() {
    const { category, active, onClick } = this.props;

    return (
      <div className={style.container}>
        {category.map((_el, index) => {
          return (
            <span key={'category-' + index} to={null} className={`${style.category__link} ${active === _el.id && style[`link-active`]}`} onClick={() => onClick(_el.id)}>
              <span className={style.category__name}>{_el.category}</span>
            </span>
          )
        })}
      </div>
    )
  }
}
export default Category;