import React, { PureComponent } from "react";

import style from "./Section.module.scss";

class SectionIntro extends PureComponent {
  render() {
    const { title, caption, pattern, data } = this.props;
    
    let mainTitle = title
    let description = caption

    if (data) {
      if (data.about_header !== null) {
        const about_header = data.about_header

        mainTitle = about_header.title
        description = about_header.description
      }
    }

    return (
      <section className={style.section__intro}>
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundImage: `url(${pattern})`,
          backgroundRepeat: "repeat",
          backgroundSize: 150
        }} />
        <div className={style.intro__inner}>
          <div className={style.intro__title}>{mainTitle}</div>
          <p className={style.intro__caption}>{description}</p>
        </div>
      </section >
    )
  }
}

export default SectionIntro;