import React, { Component } from "react";

import style from './Storybook.module.scss';

const fontWeight = ['100', '200', '300', '400', '500', '600', '700', '800'];
const fontSize = ['10', '12', '14', '16', '18', '19', '20', '24', '27', '32', '36', '42', '48', '56', '64', '72'];

const getClass = (weight) => {
  const list = [style.font__label];
  list[list.length] = `${style[`font__label-${weight}`]}`;

  return list.join(" ");
}

const getClassSize = (size) => {
  const list = [`${style[`font__label--size`]}`];
  list[list.length] = `${style[`font__size-${size}`]}`;

  return list.join(" ");
}

class Storybook extends Component {
  render() {
    return (
      <div className={style.container}>
        <div className={style.container__inner}>
          <div className={style.section}>
            {
              fontWeight.map((_el) => {
                return (
                  <div className={getClass(_el)}>This is weight test label - {_el}</div>
                )
              })
            }
          </div>
          <div className={style.section}>
            {
              fontSize.map((_el) => {
                return (
                  <div className={getClassSize(_el)}>This is size test label - {_el}</div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Storybook;