import React, { PureComponent } from "react";
import { EmailShareButton } from "react-share";

import { Icon } from "../../components";

import style from "./Share.module.scss";

class ShareEmail extends PureComponent {
  render() {
    const { url, subject, body, separator, customStyle, customIconStyle } = this.props;

    return (
      <div className={`${style.share} ${customStyle}`}>
        <EmailShareButton url={url} subject={subject} body={body} separator={separator}>
          <Icon type="email" className={`${style.share__icon} ${customIconStyle}`} />
        </EmailShareButton>
      </div>
    )
  }
}

export default ShareEmail;