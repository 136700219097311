import React from "react";

import style from "./DetailsBanner.module.scss";

const DetailsBanner = ({ image }) => (
  <div className={style.details__banner}>
    <img src={image} className={style.banner__image} />
    <div className={style.banner__overlay} />
  </div>
)

export default DetailsBanner;