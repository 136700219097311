import * as React from "react";

function SvgIg(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.908 13.582c-.084-1.914-.393-3.23-.837-4.37A8.787 8.787 0 0034.99 6.02a8.862 8.862 0 00-3.187-2.075c-1.146-.443-2.455-.753-4.368-.837-1.928-.092-2.54-.113-7.43-.113-4.889 0-5.5.021-7.421.106-1.914.084-3.229.394-4.369.837A8.787 8.787 0 005.02 6.019a8.866 8.866 0 00-2.075 3.187c-.443 1.147-.753 2.455-.837 4.369-.091 1.927-.113 2.54-.113 7.428 0 4.89.022 5.502.106 7.422.084 1.914.394 3.23.837 4.37a8.88 8.88 0 002.082 3.193c.9.914 1.991 1.625 3.187 2.075 1.147.443 2.455.753 4.369.837 1.92.085 2.533.106 7.422.106 4.89 0 5.501-.021 7.422-.106 1.913-.084 3.228-.394 4.368-.837a9.211 9.211 0 005.27-5.269c.442-1.147.752-2.455.837-4.369.084-1.92.105-2.532.105-7.422 0-4.889-.007-5.5-.092-7.421zm-3.242 14.703c-.078 1.758-.373 2.708-.62 3.341a5.974 5.974 0 01-3.419 3.42c-.633.245-1.59.54-3.341.618-1.9.085-2.47.106-7.274.106-4.805 0-5.382-.021-7.274-.106-1.76-.077-2.709-.373-3.342-.619a5.54 5.54 0 01-2.068-1.343 5.598 5.598 0 01-1.344-2.069c-.246-.633-.542-1.59-.619-3.341-.084-1.9-.105-2.47-.105-7.274 0-4.805.02-5.382.105-7.274.077-1.76.373-2.709.62-3.342a5.474 5.474 0 011.35-2.068A5.59 5.59 0 019.403 6.99c.633-.246 1.59-.542 3.342-.62 1.9-.083 2.47-.105 7.274-.105 4.812 0 5.381.022 7.274.106 1.759.077 2.708.373 3.341.62.781.287 1.492.745 2.069 1.343a5.598 5.598 0 011.343 2.068c.247.633.542 1.59.62 3.342.084 1.899.105 2.469.105 7.274 0 4.804-.021 5.367-.105 7.267z"
        fill="#000"
      />
      <path
        d="M20.005 11.753c-5.107 0-9.251 4.143-9.251 9.25 0 5.108 4.144 9.252 9.25 9.252 5.108 0 9.252-4.144 9.252-9.251 0-5.108-4.144-9.251-9.251-9.251zm0 15.252a6.002 6.002 0 010-12.002 6.002 6.002 0 010 12.002zM31.781 11.387a2.16 2.16 0 11-4.32 0 2.16 2.16 0 014.32 0z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgIg;
