import * as React from "react";

function SvgFb(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.123 3H4.875C3.84 3 3 3.84 3 4.877v30.248C3 36.16 3.84 37 4.877 37h16.285V23.852h-4.416v-5.147h4.416v-3.787c0-4.393 2.681-6.783 6.6-6.783 1.876 0 3.489.14 3.959.202v4.59h-2.702c-2.132 0-2.544 1.014-2.544 2.5v3.278h5.096l-.664 5.147h-4.432V37h8.648C36.16 37 37 36.16 37 35.124V4.874C37 3.84 36.16 3 35.123 3z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgFb;
