import React, { Component  } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import MetaTags from 'react-meta-tags';

import { Header, TitleBanner, Category, SectionCareer, Button } from "../../components";

import style from "./Clients.module.scss";
import { career, category, clients } from "../../api/data";
import pattern from "../../assets/patterns/pattern04.svg";
import { useSelector} from 'react-redux';


function HookWrapper({
  render
}) {
  return render(loadCategory(), loadClients(),loadBanner2(), loadGlossary(), loadSeo());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function loadBanner2() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    const svg = banners.svg
    const svg_clients = svg.svg_clients
    let svg_file = pattern
    if(svg_clients.file !== undefined){
      svg_file = svg_clients.file.file_url || null
    }
    
    let info = {
       svg: svg_file
    }
    return info;
  } else {
    let info = {  svg: null }
    return info;
  }

}

function loadCategory() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    
    const categories = banners.client_categories
    let locale = Cookies.get("locale") || "EN"
      
    let lang = 'english'
      
    let All = 'All'

    if(locale==="EN"){
        lang = 'english'
        All = 'All'
    }else{
        lang = 'bahasa'
        All = 'Semua'
  }


   let categoryData = []

    categoryData.push({
      id:10010,
      category: All
    })
   

    categories.map(el => {
      let title  = el.title
      
      categoryData.push({
        id: el.id,
        category: title[lang] || title[lang]
      })  
    })
    return categoryData;
  } else {
    let info = []
    return info;
  }
}

function loadClients() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    const clients = banners.clients
   
    let clientsData = clients.map(el => {
     return  {
        id: el.id,
        categoryId: 10010,
        categoryId2: el.categoryId,
        client_logo: el.image.file_url
      }
    })
    let clientsData2 = clients.map(el => {
      return  {
         id: el.id,
         categoryId:  el.categoryId,
         categoryId2: el.categoryId,
         client_logo: el.image.file_url
       }
     })

    clientsData = [...clientsData, ...clientsData2]
    return clientsData;
  } else {
    let info = []
    return info;
  }
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)

  return banners
}

function loadSeo() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return banners.seo_clients
  }else{
    return{
      title:"Aura Design",
      meta_keyword:"Aura Design",
      meta_description:"Aura Design",
    }
  }
 
}

class Clients extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeCategory: 0,
    }
  }

  componentDidMount() {
   const {pathname} = this.props.location
   let locale = Cookies.get("locale") || "EN"
   
   if(locale == 'EN'&& pathname.includes('klien-kami')){
    setTimeout(() => {
      this.props.history.push("/our-client");
                   }, 500);  
   }

   if(locale == 'ID'&& pathname.includes('our-client')){
    setTimeout(() => {
      this.props.history.push("/klien-kami");
                   }, 500);  
   }

  }

  _renderClientCard = (el) => {
		if (el.categoryId === this.state.activeCategory){
			return (
				<div className={style.client__link}>
					<div className={style.client__card}>
						<img src={el.client_logo} className={style.client__logo} />
						<div className={style.client__overlay} />
					</div>
				</div>
			)
		}
    
  }
  printData(data) {
    // console.log("active data", data)
    return data
  }

  _setActive = (data) => {
    this.setState({
      activeCategory: data,
    })
  }
  currentCategory = (categoryData) => {
      if(this.state.activeCategory === 0) {
        if(categoryData.length > 0) {
          let category = categoryData[0]
          this.setState({
            activeCategory: category.id,
          })
          return category.id
        }else{
          return 1
        }
      }else{
        return this.state.activeCategory
      }
  }

  render() {
    const { data } = category;
    
    return (
      <HookWrapper render={ (categoryData, clientsData,props, glossary, seo) =>
      <>
        <MetaTags>
          <title>{seo.title}</title>
          <meta name="description" content={seo.meta_description} />
          <meta name="keyword" content={seo.meta_keyword} />
          <meta property="og:title" content={seo.title} />
        </MetaTags>
        <Header />
        <TitleBanner title={glossary.clients || 'Our Clients'} pattern={props.svg || pattern} backgroundSize={70} />
        <div className={style.client__content}>
          <div className={style.client__inner}>
            <Category category={categoryData.length >0 ? categoryData : data} active={this.currentCategory(categoryData)}  onClick={this._setActive} />
            <div className={style.client__list}>
              {clientsData.map(_el => (
                <>{this._renderClientCard(_el)}</>
              ))}
            </div>
            {
							/*
							<div className={style.button__panel}>
              	<Button buttonName="Load More" full />
            	</div>
							*/
						}
          </div>
        </div>
        <SectionCareer title={career.title} content={career.content} data={loadBanner()}/>
      </>
      }/>
    )
  }
}

export default Clients;