import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import style from "./Navbar.module.scss";

class Navbar extends PureComponent {
  render() {
    const { type, prevItem, nextItem, prevLink, prevName, nextLink, nextName, prevLogo, nextLogo, glossary } = this.props;

    return (
      <div className={style.container}>
      <>
      {
        prevItem !== null && (
          <div className={style.prev__container}>
            <Link className={style.prev__link} to={prevLink}>
               <>
              {
                prevName !=='' && (<span className={style.navbar__label}>{glossary.previous || 'Previous'}</span>)
              }
              </>
              {type === "image" ? (
                <img src={ prevItem.image.file_url||prevLogo} className={style.navbar__logo} />
              ) : (
                  <span className={style.navbar__title}>{prevName}</span>
              )}
            </Link>
          </div>
        )
      }

      </>
       
        <div className={style.next__container}>
        <>
        {
          nextItem !== null && (
            <Link className={style.next__link} to={nextLink}>
              {type === "image" ? (
                <img src={nextItem.image.file_url||nextLogo} className={style.navbar__logo} />
              ) : (
                <span className={style.navbar__title}>{nextName}</span>
              )}
              <>
              {
                nextName !=='' && ( <span className={style.navbar__label}>{glossary.next || 'Next'}</span>)
              }
              </>
            </Link>
          )
        }
        </>

         
        </div>
      </div>
    )
  }
}

export default Navbar;