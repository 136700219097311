import React from "react";

import style from "./Content.module.scss";

const ContentPicture = ({ image }) => (
  <>
  {image!==''?(<div className={`${style[`content__container--image`]}`}>
  <img src={image} className={style.content__image} />
</div>):(<div></div>)}
</>);

export default ContentPicture;