import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import withSizes from "react-sizes";
import { Header, Button } from '../../components';
import Cookies from "js-cookie";
import style from './Notfound.module.scss';
import { search } from '../../api/data'
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux'
import { searchToServer } from '../../store/banner'

function HookWrapper({
  render
}) {
  return render( loadGlossary());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}




class Notfound extends Component {

   constructor(props) {
    super(props);
  
  }

  
  render() {
    const { isMobile } = this.props;
    const locale = Cookies.get("locale") || "EN"
    let title = "Page Not Found"
    let caption = "Sorry, there is nothing here."
    let keyword = "The page you are looking for cannot be found"
    let buttonText = "GO TO HOME"
    if(locale === 'ID'){
      title = "Halaman Tidak Ditemukan"
      caption = "Maaf, tidak ada informasi disini."
      keyword = "Halaman yang anda cari tidak dapat ditemukan"
      buttonText = "KEMBALI KE BERANDA"
    }

    return (
      <HookWrapper render={(glossary) =>
      <>
       <MetaTags>
          <title>{title} | Aura Design</title>
          <meta name="description" content=""/>
          <meta name="keyword" content="" />
          <meta property="og:title" content="Page Not Found" />
        </MetaTags>
        <Header />
       
        <div className="page__container">
          <div className={style.container}>
            <div className={style.caption}>{caption}</div>
            <h3 className={style.keyword}>{keyword}</h3>
            <div className={style.button__panel}>
              <Button type="redirect" link="/" full={isMobile} buttonName={buttonText} />
            </div>
          </div>
        </div>
      </>
      } />
    );
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width <= 500
});

export default withSizes(mapStateToProps)(Notfound);