import * as React from "react";

function SvgRightWhite(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1l14 14L1 29" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}

export default SvgRightWhite;
