import React, { PureComponent } from "react";

import { Select } from "../../components";
import GoogleMapView from "./GoogleMapView";

import style from "./MapView.module.scss";
import { locations } from "../../api/data";

class MapView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      details: {},
      longitude: '',
      latitude: ''
    }
  }

  componentDidMount() {
    const { data } = this.props;
    let loc = [];
    data.map(item =>{
      if(item.locations!==undefined){
         item.locations.map(it =>{
          loc.push(it)
         })
      }
     
    })
    const first_data = loc[0];
    const { value } = first_data;
    const pos = value.split(',');

    this.setState({
      details: first_data,
      longitude: pos[1],
      latitude: pos[0].trim()
    })
  }

  componentDidUpdate(){
    const { data } = this.props;
    let loc = [];
    data.map(item =>{
      if(item.locations!==undefined){
         item.locations.map(it =>{
          loc.push(it)
         })
      }
     
    })
    const first_data = loc[0];
    const { value } = first_data;
    const pos = value.split(',');

    this.setState({
      details: first_data,
      longitude: pos[1],
      latitude: pos[0].trim()
    })
  }

  _handleChange = e => {
    const { longitude, latitude } = this.state
    const position = e.value;
    const pos = position.split(',');

    if (latitude !== pos[0] && longitude !== pos[1]) {
      this.setState({
        details: e,
        latitude: pos[0],
        longitude: pos[1]
      })
    }
  }

  render() {
    const { details, longitude, latitude } = this.state;
     const { data, glossary } = this.props;
    let loc = [];
    data.map(item =>{
      if(item.locations!==undefined){
         item.locations.map(it =>{
          loc.push(it)
         })
      }
    })

    return (
      <div className={style.container}>
        <div className={style.line__separator} />
        <div className={style.map__title}>{glossary.location}</div>
        <Select data={loc} onChange={this._handleChange} glossary={glossary}/>
        <div className={style.map__container}>
          <GoogleMapView
            latitude={latitude}
            longitude={longitude}
            data={details}
          />
        </div>
      </div>
    )
  }
}

export default MapView;