import * as React from "react";

function SvgTwt(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.007 34c13.208 0 20.431-10.772 20.431-20.112 0-.306-.006-.61-.02-.913A14.49 14.49 0 0038 9.315a14.505 14.505 0 01-4.124 1.112 7.116 7.116 0 003.158-3.91 14.541 14.541 0 01-4.56 1.716A7.226 7.226 0 0027.232 6c-3.965 0-7.18 3.165-7.18 7.068 0 .554.062 1.094.185 1.611A20.497 20.497 0 015.436 7.294a6.968 6.968 0 00-.973 3.553c0 2.452 1.268 4.617 3.196 5.884a7.211 7.211 0 01-3.252-.884l-.001.09c0 3.423 2.475 6.281 5.76 6.93a7.315 7.315 0 01-3.242.12c.914 2.809 3.565 4.853 6.708 4.91a14.552 14.552 0 01-8.919 3.026c-.579 0-1.15-.033-1.713-.098A20.563 20.563 0 0014.007 34"
        fill="#000"
      />
    </svg>
  );
}

export default SvgTwt;
