import React, { PureComponent } from "react";
import withSizes from "react-sizes";

import { Icon } from "../../components";

import style from "./Modal.module.scss";

class Modal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.visible) {
      document.body.style.overflow = 'hidden';
      document.querySelector(`.${style.modal__content}`).scroll(0, 0)
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  render() {
    const { isMobile, visible, onClose, data } = this.props;

    let profile = ''
    if(data!=null){
     profile = data.profile.replace(/(<([^>]+)>)/gi, "")
    }
    

    return (
      <div className={`${style.modal} ${visible && style[`modal-open`]}`}>
        <div className={style.modal__inner}>
          <div className={style.modal__close} onClick={onClose}>
            <Icon type={isMobile ? "close" : "closewhite"} className={`${style[`modal__close--icon`]}`} />
          </div>
          <div className={style.modal__content}>
            <img src={data !== null ? data.photo : ''} className={style.modal__image} />
            <div className={style.modal__info}>
              <div className={style.modal__name}>{data !== null && data.name}</div>
              <div className={style.modal__position}>{data !== null && data.position}</div>
              <p className={style.modal__text}>{profile}</p></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width < 768
})

export default withSizes(mapStateToProps)(Modal);

Modal.defaultProps = {
  visible: false
}