import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

import styles from "./MapView.module.scss";
import googleMapStyles from "./MapView.json";

const style = {
  width: '100%',
  height: '503px'
}

class GoogleMapView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: true
    }
  }

  _onCloseWindow = () => {
    this.setState({
      showInfo: false
    })
  }

  _showInfo = () => {
    this.setState({
      showInfo: true
    })
  }

  render() {
    const { longitude, latitude, data } = this.props;
    const { showInfo } = this.state;

    const newLatitude = parseFloat(latitude);
    const newLongitude = parseFloat(longitude);

    return (
      <LoadScript googleMapsApiKey="AIzaSyC1-R6q_eul6NveW1zq2N41L8a8itSOSE8">
        <GoogleMap
          mapContainerStyle={style}
          center={{ lat: newLatitude, lng: newLongitude }}
          options={{
            styles: googleMapStyles
          }}
          zoom={15}
        >
          <Marker
            position={{ lat: newLatitude, lng: newLongitude }}
            onLoad={this._showInfo}
            onClick={this._showInfo}
          >
            {showInfo && (
              <InfoWindow
                onCloseClick={this._onCloseWindow}
              >
                <div className={styles.info__container}>
                  <div className={styles.info__name}>{data.label}</div>
                  <div>{data.address}</div>
                  {/*
                  <div>{data.subdistrict}</div>
                  <div>{data.district}</div>
                  <div>{data.province}</div>
                  {data.postal_code && <div>{data.postal_code}, {data.country}</div>
                  */}
                  
                </div>
              </InfoWindow>
            )}
          </Marker>
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default GoogleMapView;