import {
    createSlice
} from '@reduxjs/toolkit'
import Cookies from "js-cookie";
// Slice

const initialBanners = null

const slice = createSlice({
    name: 'banners',
    initialState: {
        banners: initialBanners,
        loading: false,
        searchResults: null,
        glossary: {},
        loaded: false,
    },
    reducers: {
        loadBanner: (state, action) => {
            state.banners = action.payload;
        },
        loadOnce: (state, action) => {
            if(!state.loaded){
                 state.loaded = action.payload;
             }
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setGlossary: (state, action) => {
            if (action.payload.glossary !==undefined){
                if (action.payload.glossary.general !== undefined) {
                    
                    let general  = action.payload.glossary.general;
                    let glossary = {}
                    general.map(item=>{
                        glossary[item.key] = item.word
                    })
                    // console.log("GLOSSARY", glossary)
                    state.glossary = glossary;
                }
            }
            
        },
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;
        }
    },
});

export default slice.reducer

// Actions

const {
    loadBanner,
    loadOnce,
    setLoading,
    setSearchResults,
    setGlossary
} = slice.actions

export const loadBannerData = () => async dispatch => {
    try {
        // await api.post('/api/auth/login/', { username, password })
       dispatch(setLoading(true));
       dispatch(loadOnce(true));
      // const response = await fetch('https://run.mocky.io/v3/0214e6b5-78d3-46b5-8f5a-c96c09f2f9c7')
      let locale = Cookies.get("locale") || "EN"
      
      let lang = 'english'

      if(locale==="EN"){
          lang = 'english'
      }else{
           lang = 'bahasa'
      }

      const response = await fetch(process.env.REACT_APP_CMS_URL + '/api/getdata?lang='+lang)
       const json = await response.json();
       dispatch(setLoading(false));
       dispatch(loadBanner(json));
       dispatch(setGlossary(json));
       
    } catch (e) {
        return console.error(e.message);
    }
}

export const sendContactUsData = (payload) => async dispatch => {
    try {
        // await api.post('/api/auth/login/', { username, password })
        dispatch(setLoading(true));
        // const response = await fetch('https://run.mocky.io/v3/0214e6b5-78d3-46b5-8f5a-c96c09f2f9c7')
       
         const response = await fetch(process.env.REACT_APP_CMS_URL + '/api/testcontact', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }
         });
            return response.json(); // parses JSON response into native JavaScript objects
            dispatch(setLoading(false));
        

        } catch (e) {
            return console.error(e.message);
        }
    }

    export const searchToServer = (payload) => async dispatch => {

        let locale = Cookies.get("locale") || "EN"
      
        let lang = 'english'

        if(locale==="EN"){
            lang = 'english'
        }else{
            lang = 'bahasa'
        }
        dispatch(setLoading(true));
        dispatch(setSearchResults(null));
        const response = await fetch(process.env.REACT_APP_CMS_URL + '/api/search?lang='+lang+'&keyword=' + encodeURIComponent(payload))
        const json = await response.json();
        dispatch(setLoading(false));
        dispatch(setSearchResults(json));
    }