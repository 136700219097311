import React, { Component } from "react";
import withSizes from "react-sizes";
import MetaTags from 'react-meta-tags';
import Cookies from "js-cookie";

import { Header, SectionIntro, PageBanner, SectionCareer, Button } from "../../components";
import { useSelector } from 'react-redux'
import style from "./About.module.scss";
import { career, about } from "../../api/data";
import pattern from "../../assets/patterns/pattern01.svg";


function HookWrapper({
  render
}) {
  return render(loadBanner2(), loadGlossary(), loadSeo());
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)

  return banners
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function loadBanner2() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    const about_team = banners.about_team
    const svg = banners.svg
    const svg_about = svg.svg_about
    let svg_file = null
    if(svg_about.file !== undefined){
      svg_file = svg_about.file.file_url || null
    }
    let info = {
      title: about_team.title,
      caption: about_team.description,
      image: about_team.image,
      svg: svg_file
    }
    return info;
  } else {
    let info = { title: null, caption: null, image: null }
    return info;
  }

}

function loadSeo() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return banners.seo_about
  }else{
    return{
      title:"Aura Design",
      meta_keyword:"Aura Design",
      meta_description:"Aura Design",
    }
  }
 
}


class About extends Component {


  componentDidMount() {
    const {pathname} = this.props.location
    let locale = Cookies.get("locale") || "EN"
    
    if(locale == 'EN'&& pathname.includes('tentang')){
     setTimeout(() => {
       this.props.history.push("/about");
                    }, 500);  
    }
 
    if(locale == 'ID'&& pathname.includes('about')){
     setTimeout(() => {
       this.props.history.push("/tentang");
                    }, 500);  
    }
 
   }

  render() {
    const { isMobile } = this.props;
    const { data } = about;
    let team_title = data.team_title
    let team_image = '/assets/team-intro.png'
    let team_caption = data.team_caption

    return (
      <HookWrapper render={(props, glossary, seo) =>
        <>
          <MetaTags>
            <title>{seo.title}</title>
            <meta name="description" content={seo.meta_description} />
            <meta name="keyword" content={seo.meta_keyword} />
            <meta property="og:title" content={seo.title} />
          </MetaTags>
          <Header />
          <SectionIntro title={data.title} caption={data.caption} pattern={props.svg || pattern} data={loadBanner()} />
          <PageBanner banner={data.banner} data={loadBanner()} />
          <section className={style.team__intro}>
            <div className={style.team__inner}>
              <div className={`${style[`team__container--image`]}`}>
                <div className={`${style.team__image__container}`}>
                  <img src={props.image || team_image} className={style.team__image} />
                </div>
              </div>
              <div className={style.team__info}>
                <div className={style.team__title}>{props.title || team_title}</div>
                <p className={style.team__caption}>{props.caption || team_caption}</p>
                <div className={style.button__panel}>
                  <Button type="redirect" link="/team" full={isMobile} buttonName={ glossary.ourTeam|| 'Our Team'} />
                </div>
              </div>
            </div>
          </section>
          <SectionCareer title={career.title} content={career.content} data={loadBanner()} />
        </>
      } />
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width <= 500
});

export default withSizes(mapStateToProps)(About);