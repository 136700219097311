import React, { Component } from "react";
import withSizes from "react-sizes";
import MetaTags from 'react-meta-tags';

import {
  Header,
  SectionIntro,
  SectionCareer,
  TeamList,
  Button,
  Modal
} from "../../components";
import { useSelector } from 'react-redux'
import style from "./Team.module.scss";
import { team, career } from "../../api/data";
import pattern from "../../assets/patterns/pattern01.svg";

function HookWrapper({
  render
}) {
  return render(loadTeams(), loadAbout(),loadBanner2(), loadSeo());
}

function loadAbout() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners != null) {
    return banners.about_company
  }
  return {
    title: null,
    description: null,
    image: null
  }
}

function loadBanner2() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    const svg = banners.svg
    const svg_teams = svg.svg_teams
    let svg_file = pattern
    if(svg_teams.file !== undefined){
      svg_file = svg_teams.file.file_url || null
    }
    
    let info = {
       svg: svg_file
    }
    return info;
  } else {
    let info = {  svg: null }
    return info;
  }

}

function loadSeo() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return banners.seo_teams
  }else{
    return{
      title:"Aura Design",
      meta_keyword:"Aura Design",
      meta_description:"Aura Design",
    }
  }
 
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)

  return banners
}

function loadTeams() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners != null) {
    return banners.our_team
  }
  return {
    title: null,
    caption: null,
    teams: []
  }
}


class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      currentData: null
    }
  }

  _openModal = (data) => {
    this.setState({
      modal: true,
      currentData: data
    })
  }

  _closeModal = () => {
    this.setState({
      modal: false,
      currentData: null
    })
  }

  render() {
    const { modal, currentData } = this.state;
    const { isMobile } = this.props;
    const { data } = team;
    const { teams } = data;
    const title = 'Creating the Best Architecture Firm';
    const description = 'The secret is a smoothly working team of disparate people who are each endowed with some special talent. Who come together like a symphony to create beautiful music.';
    const image = '/assets/company-intro.png';
    return (
      <HookWrapper render={(props, about, info,seo) =>
        <>
         <MetaTags>
            <title>{seo.title}</title>
            <meta name="description" content={seo.meta_description} />
            <meta name="keyword" content={seo.meta_keyword} />
            <meta property="og:title" content={seo.title} />
          </MetaTags>
          <Header />
          <SectionIntro title={props.title || data.title} caption={props.caption || data.caption} pattern={info.svg || pattern} />
          <section className={style.team__content}>
            {
              props.teams != null && props.teams.map(_el => (
                <TeamList data={_el} onClick={this._openModal} />
              ))}
          </section>
          <section className={style.company__intro}>
            <div className={style.intro__inner}>
              <div className={style.intro__left}>
                <img src={about.image || image} className={style.intro__image} />
              </div>
              <div className={style.intro__right}>
                <div className={style.intro__title}>{about.title || title}</div>
                <p className={style.intro__text}>{about.description || description}</p>
                <div className={style.button__panel}>
                  <Button buttonName="About Company" type="redirect" link="/about" full={isMobile} />
                </div>
              </div>
            </div>
          </section>
          <SectionCareer title={career.title} content={career.content} data={loadBanner()} />
          <Modal visible={modal} onClose={this._closeModal} data={currentData} />
        </>
      } />
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width < 500
})

export default withSizes(mapStateToProps)(Team);