import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import withSizes from "react-sizes";
import Cookies from "js-cookie";

import { Header, Input, Button } from "../../components";
import { ReCaptcha } from 'react-recaptcha-v3'


import style from "./Contact.module.scss";
import {
  sendContactUsData
} from '../../store/banner'


function HookWrapper({
  render
}) {
  return render(dispatch(), loadGlossary(), loadSeo());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function dispatch() {
  const dispatch = useDispatch()
  return dispatch
  //dispatch(sendContactUsData(payload))
  //this.setState({success:true, fail:false, sent:true})
}

function loadSeo() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return banners.seo_contact
  }else{
    return{
      title:"Aura Design",
      meta_keyword:"Aura Design",
      meta_description:"Aura Design",
    }
  }
 
}

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {success: false, fail: false, sent:false, token:''};
    this.renderSuccess = this.renderSuccess.bind(this);
    this.renderFailed = this.renderFailed.bind(this);
    this.retry = this.retry.bind(this);
   // this.send = this.send.bind(this);
  }
  
  componentDidMount() {
    const {pathname} = this.props.location
    let locale = Cookies.get("locale") || "EN"
    
    if(locale == 'EN'&& pathname.includes('kontak')){
     setTimeout(() => {
       this.props.history.push("/contact");
                    }, 500);  
    }
 
    if(locale == 'ID'&& pathname.includes('contact')){
     setTimeout(() => {
       this.props.history.push("/kontak");
                    }, 500);  
    }
 
   }

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    this.setState({
      token: recaptchaToken
    })
  }

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  renderSuccess = (isMobile, glossary) => (
    <div className={style.status__container}>
      <div className={style.status__title}>{glossary.success||'Success'}!</div>
      <p className={style.status__content}>{glossary.yourinquiry||'Your inquiry has been successfully sent to us. Give us a little time to sort it out and get back to you. Thank you!'}</p>
      <Button full={isMobile} type="redirect" link="/" buttonName={glossary.gohome||'Go to Home'} />
    </div>
  )

  renderFailed = (isMobile) => (
    <div className={style.status__container}>
      <div className={style.status__title}>Whoops!!</div>
      <p className={style.status__content}>It seems there is some error in sending the inquiry. You can try to contact us via phone at <span className={style.status__number}>+62 (021) 94948869</span>.
      <br /><br />If you want to resubmit the form, we will take you back.</p>
      <Button full={isMobile} buttonName="Retry Form" onClick={this.retry} />
    </div>
  )
 
  send(payload,dispatch) {
    dispatch(sendContactUsData(payload))
    this.setState({success:true, fail:false, sent:true})
  }

  retry(){
    this.setState({
      success: false,
      fail: false,
      sent: false
    })
  }

  render() {
    const { isMobile } = this.props;
    const { token } = this.state;

    return (
      <HookWrapper render={(dispatch, glossary, seo) =>
      <>
        <MetaTags>
          <title>{seo.title}</title>
          <meta name="description" content={seo.meta_description} />
          <meta name="keyword" content={seo.meta_keyword} />
          <meta property="og:title" content={seo.title} />
        </MetaTags>
        <Header />
        <div className={`page__container ${style[`contact__page--container`]}`}>
          <div className={style.container}>
            <div className={`${style[`contact__container--image`]}`}>
              <img src="/assets/service-interior.png" className={style.contact__image} />
            </div>
            <div className={style.container__form}>
              <div>
                <div className={style.form__header}>
                  <h3 className={style.contact__title}>{glossary.beginWork || 'Let the Work Begin!'}</h3>
                  <p className={style.contact__caption}>
                  {glossary.whatever || 'Whatever the challenge, AURA Design is up to it.'}
                  <br />
                  {glossary.wehappy || "We're happy to discuss your project and answer any question:"}
                </p>
                </div>
                <div className={style.form__wrapper}>
                  {!this.state.sent && <Formik
                      initialValues={{
                        username: '',
                        subject: '',
                        email: '',
                        message: '',
                      }}
                      validationSchema={yup.object().shape({
                        username: yup
                          .string()
                          .required((glossary.name||'Name')+' '+(glossary.mustFilled || 'must be filled')),
                        subject: yup
                          .string()
                          .required((glossary.subject||'Subject')+' '+(glossary.mustFilled || 'must be filled')),
                        email: yup
                          .string()
                          .email(glossary.wrongFormat||'Wrong e-mail format')
                          .required((glossary.email||'Email')+' '+(glossary.mustFilled || 'must be filled')),
                        message: yup
                          .string()
                          .required(glossary.cantempty||'Message cannot be empty'),
                          /*
                        captcha: yup
                          .string()
                          .required('Required captcha')
                          */
                      })}
                      onSubmit={(values, actions) => {
                         this.send({
                           name: values.username,
                           phone: '',
                           email: values.email,
                           subject: values.subject,
                           message: values.message,
                           token: token,
                         }, dispatch)
                        setTimeout(() => {
                          /*
                          console.log("message", values)
                          if(values.message==="sukses"){
                            this.setState({success:true, fail:false, sent:true})
                          }
                          if(values.message==="gagal"){
                            this.setState({success:false, fail:true, sent:true})
                          }
                          */  
                          actions.setSubmitting(false);
                        }, 1000);
                      }}
                    >
                      {props => (
                        <form onSubmit={props.handleSubmit}>
                          <Input
                          full
                          type="text"
                          id = "username"
                          value ={props.values.username}
                          placeholder ={glossary.name ||'Name'}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.username}
                          error = { props.touched.username ? props.errors.username : ''}
                          message = {props.errors.username}
                        />
                        <Input
                          full
                          type="text"
                          id="subject"
                          value={props.values.subject}
                          placeholder={glossary.subject ||'Subject'}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error = { props.touched.subject ? props.errors.subject : ''}
                          message={props.errors.subject}
                        />
                        <Input
                          full
                          type="email"
                          id="email"
                          value={props.values.email}
                          placeholder={glossary.email ||'E-mail'}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error = { props.touched.email ? props.errors.email : ''}
                          message={props.errors.email}
                        />
                        <Input
                          full
                          type="textarea"
                          id="message"
                          value={props.values.message}
                          placeholder={glossary.yourMessage ||'Your Message'}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error = { props.touched.message ? props.errors.message : ''}
                          message={props.errors.message}
                        />
                        {/*
                        
                          <div className={style.captcha__container}>
                          <Recaptcha
                            sitekey="6Ldh2NkZAAAAAFJ7EQJJPBJkZ2ieYwaBTUHCSJhK"
                            render="explicit"
                            theme="dark"
                            verifyCallback={(res) => { 
                              console.log(res)
                              props.setFieldValue("captcha", res) 
                              }}
                            onloadCallback={() => console.log('loaded')}
                          />
                          <div className={style.captcha__message}>{props.errors.captcha}</div>
                        </div>
                        
                        */}
                        
                        <Button full={isMobile} buttonName={glossary.send||'Send'} type="submit" />
                        </form>
                      )}
                    </Formik>}
                </div>
                <>
                 {this.state.fail  &&
                    this.renderFailed(isMobile)
                  }
                  {this.state.success &&
                    this.renderSuccess(isMobile,glossary)
                  }
                  </>
                {/* {this.renderFailed(isMobile)} */}
                {/* {this.renderSuccess(isMobile)} */}
                <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    action='contact_us'
                    verifyCallback={this.verifyCallback}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      } />
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapStateToProps)(ContactUs);