import React, { PureComponent } from "react";

import { Icon } from "../../components";

import style from "./LocationModal.module.scss";

class LocationModal extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  
  _renderLocs = (el) => {
      el.locations.map(_el => (
              <>{this._renderLocations(_el.location, _el.year, _el.area)}</>
            ))
  }

  _renderLocations = (name, year, area, glossary) => (
    <div className={style.location}>
      <div className={style.location__name}>{name}</div>
      <div>
        <span className={style.location__text}>{year}</span>
        <span className={style.location__separator}>&bull;</span>
        <span className={style.location__text}>{glossary.area || 'Approx area'} {area} {glossary.sqm || 'sqm'}</span>
      </div>
    </div>
  )

  render() {
    const { visible, title, data, onClose, glossary } = this.props;

    return (
      <div className={`${style.modal} ${visible && style[`modal-open`]}`}>
        <div className={style.modal__container}>
          <div className={style.modal__header}>
            <div className={style.modal__title}>{title}</div>
            <div className={style.modal__close} onClick={onClose}>
              <Icon type="closewhite" className={style.modal__icon} />
            </div>
          </div>
          <div className={style.modal__content}>
             <>{
              data !== undefined && data.map((el) => {
                  return ( <>
                    <div className={style.city__name}>{el.city}</div>
                    
                    <>{el.locations.map((_el) => {
                      return (<>{this._renderLocations(_el.label, _el.year, _el.area, glossary)}</>)
                    })}</>
                  </> )
                })
             }</>
            
            </div>
        </div>
      </div>
    )
  }
}

export default LocationModal;