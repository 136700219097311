import * as React from "react";

function SvgYtWhite(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.176 11.225c-.461-1.652-1.812-2.954-3.526-3.4C32.52 7 20 7 20 7S7.48 7 4.35 7.794c-1.681.445-3.065 1.779-3.526 3.43C0 14.243 0 20.5 0 20.5s0 6.29.824 9.275c.461 1.652 1.812 2.954 3.525 3.4C7.512 34 20 34 20 34s12.52 0 15.65-.794c1.714-.445 3.065-1.747 3.526-3.4C40 26.79 40 20.533 40 20.533s.033-6.29-.824-9.307zM16.013 26.28V14.72L26.425 20.5l-10.412 5.781z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgYtWhite;
