import {
    configureStore
} from '@reduxjs/toolkit'
import {
    combineReducers
} from 'redux'

import banner from './banner'

const reducer = combineReducers({
    banner,
})

const store = configureStore({
    reducer,
})

export default store;