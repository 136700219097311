import React, { PureComponent } from "react";
import ReactPaginate from "react-paginate";

import { Icon } from '../../components';

import style from "./Pagination.module.scss";

class Pagination extends PureComponent {
  constructor(props) {
    super(props);

  }

  handlePageClick = (data) => {
    let selected = data.selected;
    // console.log(selected)
    
  };

  attack = (data) => {
    // console.log("data", data)
    this.props.sendAttack(data.selected);
  }

  render() {
    const { page, active } = this.props;
    const { _onClick, _context } = this.props;
    return (
      <ReactPaginate
        pageCount={page}
        previousLabel={<Icon type="pageleft" />}
        nextLabel={<Icon type="pageright" />}
        containerClassName={style.pagination}
        previousClassName={`${style.arrow} ${style[`arrow-left`]}`}
        nextClassName={`${style.arrow} ${style[`arrow-right`]}`}
        disabledClassName={`${style[`arrow-disabled`]}`}
        pageClassName={style.page}
        activeClassName={style.page__active}
        onPageChange = {
          (data) => this.attack(data)
        }
      />
    )
  }
}

export default Pagination;