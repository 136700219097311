import * as React from "react";

function SvgPlus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.323 18.337h14.892V3.324h3.57v15.013h14.892v3.447H21.785v14.892h-3.57V21.784H3.323v-3.447zM14.892 0v15.014H0V25.108h14.892V40h10.216V25.108H40V15.014H25.108V0H14.892z"
        fill="#E75416"
      />
    </svg>
  );
}

export default SvgPlus;
