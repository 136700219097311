import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import MetaTags from 'react-meta-tags';
import withSizes from "react-sizes";

import { Header, TitleBanner, Category, SectionCareer, Button } from "../../components";

import style from "./Projects.module.scss";
import { category, project, career } from "../../api/data";
import pattern from "../../assets/patterns/pattern02.svg";
import {
  useSelector
} from 'react-redux';


function HookWrapper({
  render
}) {
  return render(loadCategory(), loadProjects(), loadBanner2(), loadGlossary(), loadSeo());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function loadBanner2() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    const svg = banners.svg
    const svg_project = svg.svg_project
    let svg_file = pattern
    if(svg_project.file !== undefined){
      svg_file = svg_project.file.file_url || null
    }
    let info = {
      svg: svg_file
    }
    return info;
  } else {
    let info = {
      svg: null
    }
    return info;
  }

}

function loadCategory() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    
    const categories = banners.projects_categories
    let categoryData = []


    let locale = Cookies.get("locale") || "EN"
      
    let All = 'All'

    if(locale==="EN"){
      All = 'All'
    }else{
      All = 'Semua'
    }

    categoryData.push({
      id:10010,
      category: All
    })
    categories.map(el => {
      categoryData.push( {
        id: el.id,
        category: el.title
      })
    })
    return categoryData;
  } else {
    let info = []
    return info;
  }
}

function loadProjects() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    const project_lists = banners.project_lists
   
    let projectsData = project_lists.map(el => {
      return  {
         id: el.id,
         categoryId: 10010,
         categoryId2: el.categoryId,
         project_logo: el.image.file_url,
         project_thumb: el.image2.file_url,
         slug: el.slug
       }
     })

    let projectsData2 = project_lists.map(el => {
     return  {
        id: el.id,
        categoryId: el.categoryId,
        categoryId2: el.categoryId,
        project_logo: el.image.file_url,
        project_thumb: el.image2.file_url,
        slug: el.slug
      }
    })

    projectsData = [...projectsData, ...projectsData2]
    return projectsData;
  } else {
    let info = []
    return info;
  }
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)

  return banners
}

function loadSeo() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    return banners.seo_projects
  }else{
    return{
      title:"Aura Design",
      meta_keyword:"Aura Design",
      meta_description:"Aura Design",
    }
  }
 
}

class Projects extends Component {


  constructor(props) {
    super(props);

    this.state = {
      activeCategory: 0,
      // amount:12,
      page: 1,
      totalPage: 0,
      perPage: 12,
      showedProjects: [],
      dataProjects:[],
    }
  }

  componentDidMount() {
    const {pathname} = this.props.location
    let locale = Cookies.get("locale") || "EN"
    
    if(locale == 'EN'&& pathname.includes('proyek')){
     setTimeout(() => {
       this.props.history.push("/projects");
                    }, 500);  
    }
 
    if(locale == 'ID'&& pathname.includes('projects')){
     setTimeout(() => {
       this.props.history.push("/proyek");
                    }, 500);  
    }
 
   }

  _renderClientCard = (el) => {
		if (el.categoryId === this.state.activeCategory){
			return (
				<Link className={style.client__link}>
					<div className={style.client__card}>
						<img src={el.client_logo} className={style.client__logo} />
						<div className={style.client__overlay} />
					</div>
				</Link>
			)
		}
    
  }

  _getCatName = (categoryId, cats) => {
    let name = ''
    cats.map(it => {
      if (it.id === categoryId) {
        name = it.category
      }
    });
    return name;
  }

  _renderProjectsCard = (el, categories, key) => {
   	if (el.categoryId === this.state.activeCategory) {
         return (
          <Link to={`/project-details/${el.slug}`} className={style.project__link} key={key}>
            <div className={style.project__card}>
              <img src={el.project_thumb} className={style.project__thumbnail} />
              <img src={el.project_logo} className={style.project__logo} />
              <div className={style.project__category}>{this._getCatName(el.categoryId2, categories)}</div>
            </div>
         </Link>
         )
         
      }
  }

  _setActive = (data) => {
    this.setState({
      activeCategory: data,
      dataProjects:[]
    })
  }
  _process( projectsData){
    if(projectsData.length > 0 ){
      let projects =   projectsData.filter(_el => {
        return (_el.categoryId === this.state.activeCategory) 
      })
  
      if(this.state.dataProjects.length === 0){
        this.setState({
          dataProjects: projects,
        }, () => {
          this._loadMore(this.props.isMobile, 1)
        })
      }
    }     
  }
  _loadMore(isMobile, page = 1){
    let perPage = 12
    if (isMobile <= 768 && isMobile > 500) {
      perPage = 9
    } else if (isMobile <= 500) {
      perPage = 3
    }
    let totalShowed = perPage * page
    let totalPage = Math.ceil(this.state.dataProjects.length / perPage)
    let showedProjects = this.state.dataProjects.filter((project, index) => index < totalShowed)
    this.setState({
      showedProjects,
      totalPage,
      page
    })
  }
  currentCategory = (categoryData) => {
      if(this.state.activeCategory === 0) {
        if(categoryData.length > 0) {
          let category = categoryData[0]
          this.setState({
            activeCategory: category.id,
          })
          return category.id
        }else{
          return 1
        }
      }else{
        return this.state.activeCategory
      }
  }

  render() {
    const { data } = category;
    const {showedProjects, page, totalPage } = this.state;
    const { isMobile } = this.props; 
    
    return (
      <HookWrapper render={ (categoryData, projectsData, props, glossary, seo) =>
        <>
        { this._process(projectsData )}
          <MetaTags>
            <title>{seo.title}</title>
            <meta name="description" content={seo.meta_description} />
            <meta name="keyword" content={seo.meta_keyword} />
            <meta property="og:title" content={seo.title} />
          </MetaTags>
          <Header />
          <TitleBanner title={glossary.projects || 'Projects'} pattern={props.svg || pattern} backgroundSize={100} />
          <div className={style.projects__content}>
            <div className={style.projects__inner}>
              <Category category={categoryData.length >0 ? categoryData : data} active={this.currentCategory(categoryData)}  onClick={this._setActive} />
              <div className={style.project__list}>
                {
                  showedProjects.map((_el, index) => (
                    <>{this._renderProjectsCard(_el, categoryData, index )}</>
                  ))
                }
              </div>
              <div className={style.button__panel}>
                {
                  page < totalPage && (<Button onClick={()=>this._loadMore(isMobile, this.state.page + 1)} buttonName={glossary.loadMore || 'Load More'} full />)
                }
              </div>
            </div>
          </div>
          <SectionCareer  title={career.title} content={career.content} data={loadBanner()}/>
        </>
        }/>
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width,
})

export default withSizes(mapStateToProps)(Projects);