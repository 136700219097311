import React, { PureComponent } from "react";
import withSizes from "react-sizes";

import { Button } from '../../components';

import style from './Section.module.scss';
import { career } from '../../api/data';

class SectionCareer extends PureComponent {
  render() {
    const { isMobile, title, content, data } = this.props;

    let mainTitle = title
    let description = content
    let linkButton = 'Send Enquiry'

    if(data) {
      if(data.career !==null){
         const career = data.career
         
         mainTitle = career.title
         description = career.description
         linkButton = career.linkButton

      }
    }

    return (
      <section className={`${style.section} ${style[`section--black`]}`}>
        <div className={style.career__inner}>
          <div className={`${style.section__left} ${style[`career-left`]}`}>
            <h1 className={style.career__title}>{mainTitle}</h1>
          </div>
          <div className={`${style.section__right} ${style[`career-right`]}`}>
            <p className={style.career__caption}>{description}</p>
            <div className={style.button__panel}>
              <Button type="redirect" full={isMobile} buttonName={linkButton} link="/contact" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width <= 500
});

export default withSizes(mapStateToProps)(SectionCareer);