import React, { PureComponent } from "react";

import { Icon } from "../../components";

import style from "./Section.module.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

function HookWrapper({ render }) {
  return render(loadServices());
}

function loadServices() {
  const { banners } = useSelector((state) => state.banner);
  if (banners !== null) {
    const services = banners.services;

    return services;
  } else {
    let info = [];
    return info;
  }
}

class SectionServices extends PureComponent {
  constructor(props) {
    super(props);
    this.lessthanthree = this.lessthanthree.bind(this);
  }
  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  }

  lessthanthree(_el, i, len) {
    let locale = Cookies.get("locale") || "EN"
    let action = "service-details"
    if(locale == "ID"){
      action = "detail-layanan"
    }

    if (i < 3) {
      return (
        <Link to={"/" + action + "/" + this.slugify(_el.title)} key={i}>
          <div
            className={`${style.services} ${
              i + 1 === 2 && style[`services--mid`]
            } ${i + 1 === len && style[`services--last`]}`}
          >
            <div className={`${style[`services--inner`]}`}>
              <img src={_el.image.file_url} className={style.services__image} />
              <div className={`${style[`service__image--overlay`]}`} />
              <div
                className={`${style.services__info} ${
                  i + 1 === len ? style[`services__info--last`] : null
                }`}
              >
                <div className={`${style[`services__info--inner`]}`}>
                  <Icon type="plus" className={style.services__icon} />
                  <h4 className={style.services__title}>{_el.title}</h4>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }
  }

  render() {
    const { data } = this.props;
    const { services } = data;
    let len = services.length;
    return (
      <HookWrapper
        render={(services) => (
          <>
            <section className={style.services__section}>
              {/* <div className={style.services__section__container}> */}
                {services.map((_el, i) => (
                  <>{this.lessthanthree(_el, i, 3)}</>
                ))}
              {/* </div> */}
            </section>
          </>
        )}
      />
    );
  }
}

export default SectionServices;
