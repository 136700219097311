import React, { PureComponent } from "react";
import { TwitterShareButton } from "react-share";

import { Icon } from "../../components";

import style from "./Share.module.scss";

class ShareTwitter extends PureComponent {
  render() {
    const { title, url, via, hashtag, related, customStyle, customIconStyle } = this.props;

    return (
      <div className={`${style.share} ${customStyle}`}>
        <TwitterShareButton url={url} title={title} hashtag={hashtag} via={via} related={related}>
          <Icon type="twt" className={`${style.share__icon} ${customIconStyle}`} />
        </TwitterShareButton>
      </div>
    )
  }
}

export default ShareTwitter;