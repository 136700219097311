import React from "react";

import style from "./Content.module.scss";

const ContentSidePicture = ({ imageLeft, imageRight }) => (
  <div className={`${style[`content__container--side`]}`}>
  {imageLeft!==''?(<div className={`${style.container__image} ${style[`container__image--left`]}`}>
      <img src={imageLeft} className={style.content__image} />
    </div>):(<div></div>)}
    {imageRight!==''?(<div className={`${style.container__image} ${style[`container__image--right`]}`}>
      <img src={imageRight} className={style.content__image} />
    </div>):(<div></div>)}
   
  </div>
)

export default ContentSidePicture;