import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import Cookies from "js-cookie";

import {
  Header,
  Icon,
  ShareFacebook,
  ShareTwitter,
  PageBanner,
  SectionCareer,
  ShareEmail,
  Navbar
} from "../../components";

import style from "./ServiceDetails.module.scss";
import { career, about } from "../../api/data";
import { useSelector} from 'react-redux';

function HookWrapper({
  render
}) {
  return render(loadDetail(), loadGlossary());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

function loadDetail() {
  const {
    banners
  } = useSelector(state => state.banner)
  if (banners !== null) {
    
    const services = banners.services
    const thePath = document.location.href
    const lastItem = thePath.substring(thePath.lastIndexOf('/') + 1)
    
    let current = services.filter(el=>{
      return (slugify(el.title) == lastItem)
    })

    let i = 0;
    services.map((el, index)=>{
      if (slugify(el.title) === lastItem){
         i = index
      }
    })
    
    let prevItem = null
    let nextItem = null
    let prevName = ""
    let nextName = ""
    let prevLink = ""
    let nextLink = ""
    if(i >  0){
      prevItem  = services[i-1];
      prevName  = prevItem.title
      prevLink = "" + slugify(prevItem.title)
    }
    if(i !==  (services.length-1)){
      nextItem  = services[i+1];
      nextName  = nextItem.title
      nextLink = "" + slugify(nextItem.title)
    }

    let current_data = {
      title:null,
      caption:null,
      description:null,
      sliders: [],
      image:{
        file_url:null
      },
      prevName: prevName,
      nextName: nextName,
      prevLink: prevLink,
      nextLink: nextLink
    }

    if(current.length >0){
      let curr = current[0]
      current_data = {
        title: curr.title,
        caption: curr.caption,
        description: curr.description,
        sliders: curr.sliders,
        image: curr.image,
        prevName: prevName,
        nextName: nextName,
        prevLink: prevLink,
        nextLink: nextLink
      }
    }

    return current_data;
  } else {
    let info = {
      title:null,
      caption:null,
      description:null,
      sliders: [],
      image:{
        file_url:null
      },
      prevName: null,
      nextName: null,
      prevLink: "",
      nextLink: ""
    }
    return info;
  }
}

function loadBanner() {
  const {
    banners
  } = useSelector(state => state.banner)

  return banners
}

class ServiceDetails extends Component {


  componentDidMount() {
    let locale = Cookies.get("locale") || "EN"
    
    const {pathname} = this.props.location
    const myArr = pathname.split("/"); 
    if(myArr.length > 0){
      if(locale == 'EN'&& myArr[1].includes('detail-layanan')){
        setTimeout(() => {
          this.props.history.push("/service-details/"+myArr[2]);
                       }, 500);  
       }

       if(locale == 'ID'&& myArr[1].includes('service-details')){
        setTimeout(() => {
          this.props.history.push("/detail-layanan/"+myArr[2]);
                       }, 500);  
       }

    }
   }

  render() {
    const { data } = about;

    return (
      <HookWrapper render={ (detail, glossary) =>
      <>
       <MetaTags>
          <title>{detail.title}</title>
          <meta name="description" content={detail.title} />
          <meta name="keyword" content={detail.title} />
          <meta property="og:title" content={detail.title} />
        </MetaTags>
        <Header />
        <div className={style.main__banner}>
          <img src={detail.image.file_url||"/assets/projects-banner01.png"} className={style.banner__img} />
          <div className={style.banner__overlay} />
          <div className={style.banner__info}>
            <Icon type="plus" className={style.banner__icon} />
            <div className={style.service__name}>{detail.title || "Interior"}</div>
          </div>
        </div>
        <div className={style.service__info}>
          <div className={`${style[`service__info--inner`]}`}>
            <div className={style.service__header}>
              <div className={style.line} />
              <div className={style.service__intro}>{detail.caption || "Interiors are sensory realizations of this shared imagination."}</div>
            </div>
            <div className={style.service__content}>
              <div className={style.service__text}>
              {
                detail.description || " We believe that good design should evoke a response, stir emotion, and inspire thought – that’s why all of our meaningful creations personify a lifestyle, a feeling and a purpose."
              }
               
              </div>
              <div className={style.service__socmed}>
                <div className={style.socmed}>
                  <span className={`${style.service__text} ${style.socmed__title}`}>{ glossary.share || 'Share'}</span>
                  <ShareFacebook url={document.location.href} hashtag="test" quote={"AURA | " + detail.title} />
                  <ShareTwitter url={document.location.href} title="test" />
                  <ShareEmail url={document.location.href} subject={"AURA | " + detail.title} body={"AURA | " + detail.description} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageBanner banner={data.banner} sliders={detail.sliders} />
        <Navbar 
          prevName={detail.prevName}
          nextName={detail.nextName}
          nextLink={detail.nextLink}
          prevLink={detail.prevLink} glossary={glossary} />
        <SectionCareer title={career.title} content={career.content} data={loadBanner()}/>
      </>
      }/>
    )
  }
}

export default ServiceDetails;