import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

import { Header, Pagination, SearchResult } from '../../components';

import style from './Search.module.scss';
import { search } from '../../api/data'
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux'
import { searchToServer } from '../../store/banner'

function HookWrapper({
  render
}) {
  return render(dispatch(), loadSearchResult(), loadGlossary());
}

function loadGlossary() {
  const {
    glossary,
  } = useSelector(state => state.banner)

  return glossary
}

function dispatch() {
  const dispatch = useDispatch()
  return dispatch
}

function loadSearchResult() {
  const {
    searchResults
  } = useSelector(state => state.banner)
  //const searchResults2 = [...searchResults];
  // console.log("KELUAR PAK EKO", searchResults)
  return searchResults
}



class Search extends Component {

   constructor(props) {
    super(props);
    this.state = {page: 0, search:false, searchResult: [], keyword:'',result:false, running:false};
  }

  
   search(dispatch) {
    const query = queryString.parse(this.props.location.search)
    const search = this.state.search
    if(query.keyword !== undefined && !search){
      if(query.keyword !==''){
        const payload = query.keyword
        dispatch(searchToServer(payload))
        this.setState( {page: 0, search:true, running:true});
      }
    }
    
  }

  getAttack = (page) => {
    //console.log("my page", page)
    this.setState({
      page: page
    });
  }
  _paginate(data,context){
    let selected = data.selected;
    context.setState({page: selected});
    //console.log(context.state.result)
  }
  componentDidMount() {
    this.setState({
      page: 0,
      keyword: '',
      search: false,
      searchResult: [],
      result: false
    });
  }
  render() {
    const { search_result, search_empty } = search;
    const { page } = this.state;
    const isEmpty = search_empty.length === 0;
    let result = this.state.result;
    let searchResult = this.state.searchResult;
    let keyword = this.state.keyword;
    let running = this.state.running;
    let res = []
    let res2 = []
    let ganti = false
    if (searchResult !== null) {
        //console.log("tereksekusi")
        if (searchResult[page]!==undefined){
          res2 = searchResult[page]
        }
    }
    
    const query = queryString.parse(this.props.location.search)
    
    if(query.keyword !==undefined ){
      if(query.keyword !== ""){
        if (this.state.keyword !== query.keyword || this.state.keyword === '') {
            //console.log("GANTI KEYWORD")
            this.setState({
              page: 0,
              keyword: query.keyword,
              search: false,
              searchResult: [],
              result: false
            });
            res2 = []
            ganti = true
            searchResult = []
        }
        }
      }
  
    
    return (
      <HookWrapper render={(dispatch, searchResults, glossary) =>
      <>
       <MetaTags>
          <title>{glossary.search} {keyword}</title>
          <meta name="description" content={glossary.search} />
          <meta name="keyword" content={glossary.search} />
          <meta property="og:title" content={glossary.search} />
        </MetaTags>
        <Header />
        <div style={{display:'none'}}>{
        searchResult.length ==0 && setTimeout(() => {
          this.search(dispatch)
          if (running) {
            if(searchResults!== null){
              //console.log('KETEMUUUUU', searchResults)
              const src = searchResults
              if(src['status']){
                  const result1 = searchResults['result'];
                  res = result1[page]
                  this.setState({searchResult: searchResults['result'],result:true, search:true, running:false})
                  res2 = res
              }else{
                this.setState({searchResult: [],result:false, search:true, running:false})
              }
            }
           // console.log("masuk pak eko", searchResults)
          }
         
          
           }, 1000)
        }
        </div>
        <div className="page__container">
          <div className={style.container}>
            <div className={style.caption}>{glossary.resultRequest || 'The result for the request'}: </div>
            <h3 className={style.keyword}> {keyword}</h3>
            <div className={style.search__results}>
             {!running && ( 
               <>
               {
                res2[0] !== undefined && res2.map(_el => (
                 <SearchResult page={_el.page} breadcrumbs={_el.breadcrumbs} link={_el.link}/>     
              ))
             }</>
             )}
             {result && (
              <>
              {
                (!ganti && res2[0] !== undefined) && (
                    <>
                      <Pagination page={searchResult.length} active={1} sendAttack={this.getAttack.bind(this)} />
                    </>
                  )
                }
                
              </>
             )}

             {!running && (
              <>
                
                {
                  (searchResult.length === 0 && this.state.search) && (
                    <>
                      <div className={style.caption__title}>{glossary.whoops || 'Whoops!'}</div>
                      <div className={style.caption}>{glossary.yourRequest || 'At your request, nothing found. Try another keyword and give a go.'}</div>
                    </>
                  )
                }
              </>
             )}
             
             
            </div>
          </div>
        </div>
      </>
      } />
    );
  }
}

export default Search;