import React from "react";

import style from "./Content.module.scss";

const ContentPictureText = ({ image, title, children }) => (
  <div className={`${style[`content__container--side`]}`}>
    <div className={`${style.container__image} ${style[`container__image--left`]}`}>
      <img src={image} className={style.content__image} />
    </div>
    {title!==''?(<div className={style.container__text}>
      <div className={style.content__line} />
      <div className={style.content__title}>{title}</div>
      <div className={style.content__text}>{children}</div>
    </div>):(<div></div>)}
  </div>
)

export default ContentPictureText;